import { createSlice } from '@reduxjs/toolkit';

export const eventSlice = createSlice({
  name: 'event',
  initialState: {
    selected: null,
  },
  reducers: {
    setSelected: (state, action) => {
      const { payload } = action;
      state.selected = payload;
    },
    reset: (state) => {
      state.selected = null;
    },
  },
});

export const { setSelected, reset } = eventSlice.actions;
export default eventSlice.reducer;

import * as yup from 'yup';
import i18n from 'i18next';
import { format } from 'date-fns';
import addDateValidation from '../addDateValidation';

const MIN_LENGTH = 2;
const MAX_LENGTH = 255;
const today = new Date();
today.setHours(0, 0, 0, 0);

const addValidation = (fieldName) => {
  const { t } = i18n;
  switch (fieldName) {
    case 'userName':
      return yup
        .string()
        .min(MIN_LENGTH, t('error.minLength', { minLength: MIN_LENGTH }))
        .max(MAX_LENGTH, t('error.maxLength', { maxLength: MAX_LENGTH }))
        .required(t('error.requiredField'));
    case 'firstName':
      return yup
        .string()
        .min(MIN_LENGTH, t('error.minLength', { minLength: MIN_LENGTH }))
        .max(MAX_LENGTH, t('error.maxLength', { maxLength: MAX_LENGTH }))
        .required(t('error.requiredField'));
    case 'lastName':
      return yup
        .string()
        .min(MIN_LENGTH, t('error.minLength', { minLength: MIN_LENGTH }))
        .max(MAX_LENGTH, t('error.maxLength', { maxLength: MAX_LENGTH }))
        .required(t('error.requiredField'));
    case 'email':
      return yup
        .string()
        .min(MIN_LENGTH, t('error.minLength', { minLength: MIN_LENGTH }))
        .email(t('error.email'))
        .required(t('error.requiredField'));
    case 'password':
      return yup
        .string()
        .min(8, t('error.password'))
        .matches(/(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,}/, t('error.password'))
        .required(t('error.requiredField'));
    case 'passwordAgain':
      return yup
        .string()
        .oneOf([yup.ref('password'), ''], t('error.passwordAgain'))
        .required(t('error.requiredField'));
    case 'zipCode':
      return yup
        .string()
        .matches(/^(105)([1-6]{1})/, t('error.zipCode'))
        .required(t('error.requiredField'));
    case 'street':
      return yup
        .string()
        .min(MIN_LENGTH, t('error.minLength', { minLength: MIN_LENGTH }))
        .max(MAX_LENGTH, t('error.maxLength', { maxLength: MAX_LENGTH }))
        .required(t('error.requiredField'));
    case 'houseNumber':
      return yup
        .string()
        .matches(/^[1-9]\d*(?:[/]{1}(?:[a-zA-Z]{1}))?$/, t('error.invalidField'))
        .required(t('error.requiredField'));
    case 'floor':
      return yup
        .string()
        .matches(/^\d+$/, t('error.number'));
    case 'door':
      return yup
        .string()
        .matches(/^\d+$/, t('error.number'));
    case 'phoneNumber':
      return yup
        .string()
        .matches(/^[+]{1}\d+$/, t('error.invalidField'))
        .min(12, t('error.minLength', { minLength: 12 }))
        .max(15, t('error.maxLength', { maxLength: 15 }))
        .required(t('error.requiredField'));
    case 'gender':
      return yup
        .string()
        .required(t('error.requiredField'));
    case 'birthDate':
      return addDateValidation(t)
        .test(
          'is-valid-birthday',
          t('error.futureDate', { maxDate: format(today, 'yyyy-MM-dd') }),
          (value) => (new Date(value) < today),
        );
    default:
      return null;
  }
};

export default addValidation;

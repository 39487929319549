import * as yup from 'yup';
import addValidation from './addValidation';

const updateUserValidationSchema = () => (yup.object({
  userName: addValidation('userName'),
  email: addValidation('email'),
  phoneNumber: addValidation('phoneNumber'),
}));

export default updateUserValidationSchema;

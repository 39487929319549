import i18next from 'i18next';

export default function getErrorMessage(errorData) {
  if (!errorData.response.data && errorData.code === 'ERR_NETWORK') {
    return i18next.t('common.networkError');
  }

  if (errorData.response.data.code) {
    return errorData.response.data.message;
  }

  if (errorData.response.status === 401) {
    return errorData.response.data.message;
  }

  if (!errorData.response.data.error) {
    return i18next.t('common.error');
  }

  return errorData.response.data.error;
}

import * as yup from 'yup';
import addValidation from './addValidation';

const createSignUpValidationSchema = () => (yup.object({
  userName: addValidation('userName'),
  email: addValidation('email'),
  password: addValidation('password'),
  passwordAgain: addValidation('passwordAgain'),
  firstName: addValidation('firstName'),
  lastName: addValidation('lastName'),
  zipCode: addValidation('zipCode'),
  street: addValidation('street'),
  houseNumber: addValidation('houseNumber'),
  floor: addValidation('floor'),
  door: addValidation('door'),
  phoneNumber: addValidation('phoneNumber'),
}));

export default createSignUpValidationSchema;

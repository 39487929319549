import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { useFormikContext } from 'formik';
import CheckboxField from '../../CheckboxField';

function PolicyPart() {
  const { lang } = useSelector((state) => state.i18n);
  const [privacyStatement, setPrivacyStatement] = useState('#');
  const [generalTerm, setGeneralTerm] = useState('#');
  const { values } = useFormikContext();
  const fields = Object.keys(values).filter((field) => ['policyAccepted', 'privacyPolicyAccepted', 'newsletter'].includes(field));

  const fetchPrivacyStatement = (async () => {
    await axios.get(`${process.env.REACT_APP_API_URL}/api/content/privacy-statement`)
      .then((res) => {
        setPrivacyStatement(res.data.fileName);
      }).catch(() => {
        setPrivacyStatement('#');
      });
  });

  const fetchGeneralTerm = (async () => {
    await axios.get(`${process.env.REACT_APP_API_URL}/api/content/general-term-and-condition`)
      .then((res) => {
        setGeneralTerm(res.data.fileName);
      }).catch(() => {
        setGeneralTerm('#');
      });
  });

  useEffect(() => {
    fetchPrivacyStatement();
    fetchGeneralTerm();
  }, [lang]);

  const renderElement = (fieldName) => {
    switch (fieldName) {
      case 'policyAccepted':
        return (
          <CheckboxField
            name={fieldName}
            label={(
              <Trans
                i18nKey={`user.${fieldName}`}
                components={[
                  <a id="policyAcceptedLink" target={privacyStatement !== '#' ? '_blank' : '_self'} rel="noopener noreferrer" href={privacyStatement}>privacyStatement</a>,
                ]}
              />
            )}
            marginTop="0px"
          />
        );
      case 'privacyPolicyAccepted':
        return (
          <CheckboxField
            name={fieldName}
            label={(
              <Trans
                i18nKey={`user.${fieldName}`}
                components={[
                  <a id="privacyPolicyAcceptedLink" target={generalTerm !== '#' ? '_blank' : '_self'} rel="noopener noreferrer" href={generalTerm}>generalTerm</a>,
                ]}
              />
            )}
            marginTop="0px"
          />
        );
      case 'newsletter':
        return (
          <CheckboxField
            name={fieldName}
            label={(<Trans i18nKey={`user.${fieldName}`} />)}
            marginTop="0px"
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="d-flex row justify-content-center mb-4">
      {fields.map((fieldName) => (
        <div className="col-12 col-sm-12 col-md-12 col-lg-12" key={fieldName}>
          {renderElement(fieldName)}
        </div>
      ))}
    </div>
  );
}

export default PolicyPart;

import React from 'react';
import SignUpForm from '../../components/User/SignUp/SignUpForm';

export default function SignUp() {
  return (
    <div className="container container__wrap sign-up col-12 col-sm-12 col-md-10 col-lg-10 col-xl-8 col-xxl-8">
      <div className="row d-flex justify-content-center">
        <SignUpForm />
      </div>
    </div>
  );
}

import * as yup from 'yup';
import addValidation from './addValidation';

const connectionValidationSchema = () => (yup.object({
  lastName: addValidation('lastName'),
  firstName: addValidation('firstName'),
  phoneNumber: addValidation('phoneNumber'),
}));

export default connectionValidationSchema;

import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'formik';
import { Button, Divider } from '@mui/material';
import InputField from '../InputField';
import SelectField from '../../SelectField';
import ConnectionPart from './ConnectionPart';
import { deleteChild, deleteConnection, reset } from '../services/authSlice';
import DynamicDialog from '../Profile/DynamicDialog';
import UpdateChildForm from '../Profile/Child/UpdateChildForm';
import DeleteConfirm from '../Profile/Child/DeleteConfirm';
import UpdateConnection from '../Profile/Child/UpdateConnection';
import AddConnection from '../Profile/Child/AddConnection';
import getPattern from '../../../helpers/getPattern';

function ChildPart({
  init, initConnection, readOnly, extraActions, oneDelete,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);
  const [dataForDialog, setDataForDialog] = useState({});
  const [typeForDialog, setTypeForDialog] = useState('updateChild');
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { loading, message } = useSelector((state) => state.auth);
  const genderOptions = [
    {
      value: '0',
      label: t('child.genderOptions.boy'),
    },
    {
      value: '1',
      label: t('child.genderOptions.girl'),
    },
  ];

  const handleClose = () => {
    setOpenDialog(false);
    setDataForDialog({});
  };

  const handleClickOpen = (data) => {
    setErrorMessage(null);
    setDataForDialog(data);
    setOpenDialog(true);
  };

  const handleItem = (item, type) => {
    setTypeForDialog(type);
    handleClickOpen(item);
  };

  const actionCallbacks = {
    close: handleClose,
    handle: handleItem,
  };

  useEffect(() => {
    switch (loading) {
      case 'failed':
        setErrorMessage(message);
        setIsLoading(false);
        break;
      case 'pending':
        setIsLoading(true);
        break;
      case 'succeeded':
        setIsLoading(false);
        setOpenDialog(false);
        break;
      default:
    }
    dispatch(reset());
  }, [loading, message]);

  const renderDialogContent = (type) => {
    switch (type) {
      case 'deleteChild':
        return (
          <DeleteConfirm
            title={t('pages.signUp.part2.deleteChildPrompt')}
            handleClose={handleClose}
            handleClickConfirm={() => {
              dispatch(deleteChild(dataForDialog.id));
            }}
            error={errorMessage}
            isLoading={isLoading}
          />
        );
      case 'deleteConnection':
        return (
          <DeleteConfirm
            handleClose={handleClose}
            handleClickConfirm={() => {
              dispatch(deleteConnection(dataForDialog.id));
            }}
            error={errorMessage}
            isLoading={isLoading}
          />
        );
      case 'updateConnection':
        return (
          <UpdateConnection
            connection={dataForDialog}
            handleClose={handleClose}
            errorMessage={errorMessage}
            isLoading={isLoading}
          />
        );
      case 'addConnection':
        return (
          <AddConnection
            init={dataForDialog}
            handleClose={handleClose}
            errorMessage={errorMessage}
            isLoading={isLoading}
          />
        );
      default:
        return (
          <UpdateChildForm
            child={dataForDialog}
            handleClose={handleClose}
            errorMessage={errorMessage}
            allowed={['howToCall', 'specialRequirements']}
            isLoading={isLoading}
          />
        );
    }
  };

  const renderElement = (field, index) => {
    const fieldName = `children[${index}].${field}`;

    switch (field) {
      case 'id':
        return (
          <InputField
            name={fieldName}
            label={t(`child.${field}`)}
            hidden
            disabled
          />
        );
      case 'languages':
        return null;
      case 'gender':
        return (
          <SelectField
            name={fieldName}
            label={t(`child.${field}`)}
            options={genderOptions}
            required
            disabled={readOnly}
          />
        );
      case 'specialRequirements':
        return (
          <InputField
            name={fieldName}
            label={t(`child.${field}`)}
            multiline
            disabled={readOnly}
          />
        );
      case 'connections':
        return (
          <>
            <div className="row">
              <div className="col-12 mt-4 mb-2">
                <Divider className="connection-title mb-2" variant="middle">
                  <div>{t('pages.signUp.part2.connectionTitle')}</div>
                </Divider>
              </div>
            </div>
            <div className="row">
              <FieldArray
                name={fieldName}
              >
                {(arrayHelpers) => (
                  <ConnectionPart
                    childIndex={index}
                    connectionArrayHelpers={arrayHelpers}
                    init={initConnection}
                    readOnly={readOnly}
                    extraActions={extraActions}
                    actionCallbacks={actionCallbacks}
                  />
                )}
              </FieldArray>
            </div>
          </>
        );
      default:
        return (
          <InputField
            name={fieldName}
            label={t(`child.${field}`)}
            required={['lastName', 'firstName', 'birthDate'].includes(field)}
            disabled={readOnly}
            pattern={getPattern(field)}
          />
        );
    }
  };

  return (
    <div className="row">
      <DynamicDialog
        open={openDialog}
        handleClose={handleClose}
        type={typeForDialog}
      >
        {renderDialogContent(typeForDialog)}
      </DynamicDialog>
      <FieldArray
        name="children"
        render={(formikProps) => (
          <>
            {formikProps.form.values.children.map((child, index) => {
              const fields = Object.keys(child);

              const sheet = fields.map(((field) => {
                let smNum;
                let mdNum;

                switch (field) {
                  case 'firstName':
                  case 'lastName':
                  case 'howToCall':
                  case 'parentName1':
                  case 'parentName2':
                    smNum = 6;
                    mdNum = 6;
                    break;
                  case 'birthDate':
                  case 'gender':
                    smNum = 6;
                    mdNum = 3;
                    break;
                  default:
                    smNum = 12;
                    mdNum = 12;
                }

                return (
                // eslint-disable-next-line react/no-array-index-key
                  <div className={`col-12 col-sm-${smNum} col-md-${mdNum} mb-4`} key={`${field}-${index}`}>
                    {renderElement(field, index)}
                  </div>
                );
              }));

              return (
              // eslint-disable-next-line react/no-array-index-key
                <Fragment key={`sheet-${index}`}>
                  <div className="col-12 mt-4 mb-2">
                    <Divider
                      className="sheet-divider mb-2"
                      variant="middle"
                    >
                      <div>{t('pages.signUp.part2.subTitle')}</div>
                    </Divider>
                  </div>
                  { sheet }
                  { extraActions && (
                    <div className="col-sm-12">
                      <div className="d-flex justify-content-end row">
                        <div className="col-12 col-sm-6 col-md-auto mb-4">
                          <Button
                            fullWidth
                            variant="contained"
                            type="button"
                            onClick={() => handleItem(child, 'updateChild')}
                          >
                            {t('pages.signUp.part2.editChild')}
                          </Button>
                        </div>
                        <div className="col-12 col-sm-6 col-md-auto mb-4">
                          <Button
                            fullWidth
                            variant="contained"
                            type="button"
                            onClick={() => handleItem(child, 'deleteChild')}
                          >
                            {t('pages.signUp.part2.removeChild')}
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                  { !readOnly && oneDelete && (
                    <div className="d-flex justify-content-end">
                      <div className="col-12 col-sm-auto col-md-auto mb-4 mt-4">
                        <Button
                          variant="contained"
                          type="button"
                          onClick={() => {
                            formikProps.remove(index);
                            formikProps.form.setTouched({});
                          }}
                        >
                          {formikProps.form.values.children.length === 1
                            ? t('common.button.skip')
                            : t('pages.signUp.part2.removeChild')}
                        </Button>
                      </div>
                    </div>
                  )}
                  { !readOnly && !oneDelete && formikProps.form.values.children.length > 1 && (
                    <div className="d-flex justify-content-end">
                      <div className="col-12 col-sm-auto col-md-auto mb-4 mt-4">
                        <Button
                          variant="contained"
                          type="button"
                          onClick={() => {
                            formikProps.remove(index);
                            formikProps.form.setTouched({});
                          }}
                        >
                          {t('pages.signUp.part2.removeChild')}
                        </Button>
                      </div>
                    </div>
                  )}
                </Fragment>
              );
            })}
            {!readOnly && (
              <div className="d-flex justify-content-start">
                <div className="col-12 col-sm-auto col-md-auto mb-4">
                  <Button
                    variant="contained"
                    type="button"
                    onClick={() => formikProps.push(init)}
                  >
                    {t('pages.signUp.part2.addChild')}
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      />
    </div>
  );
}

ChildPart.propTypes = {
  init: PropTypes.shape({}).isRequired,
  initConnection: PropTypes.shape({}).isRequired,
  readOnly: PropTypes.bool,
  extraActions: PropTypes.bool,
  oneDelete: PropTypes.bool,
};

ChildPart.defaultProps = {
  readOnly: false,
  extraActions: false,
  oneDelete: true,
};

export default ChildPart;

import React from 'react';
import { Formik, Form } from 'formik';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ChildPart from '../SignUp/ChildPart';

const connectionFields = [
  'id', 'lastName', 'firstName', 'phoneNumber', 'relationshipType',
];
const fields = [
  'id', 'lastName', 'firstName', 'birthDate', 'gender', 'howToCall',
  'specialRequirements',
];
const initConnection = {
  lastName: '',
  firstName: '',
  phoneNumber: '',
  relationshipType: '',
};

function MyChildrenForm() {
  const { t } = useTranslation();
  const { account } = useSelector((state) => state.auth);

  if (account.children?.length === 0) {
    return (
      <div className="m-3 d-flex justify-content-center">
        {t('common.noContent')}
      </div>
    );
  }

  const newArray = account.children.map((child) => {
    let connections;
    const childData = fields.reduce((formData, value) => (
      { ...formData, [value]: child[value] }
    ), {});

    if (child.connections) {
      connections = child.connections.map((connection) => {
        const strName = connection.name;
        const copy = {
          ...connection,
          lastName: strName.substring(strName.indexOf(' ') + 1, strName.length),
          firstName: strName.substring(0, strName.indexOf(' ')),
        };
        const connectionData = connectionFields.reduce((formData, value) => (
          { ...formData, [value]: copy[value] }
        ), {});

        return {
          ...connectionData,
        };
      });
    }

    return {
      ...childData,
      gender: childData.gender === 'Lány' ? 1 : 0,
      connections,
    };
  });

  const formValues = {
    children: newArray,
  };
  const initialValues = formValues;

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
      >
        <div className="col">
          <Form noValidate>
            <ChildPart init={{}} initConnection={initConnection} readOnly extraActions />
          </Form>
        </div>
      </Formik>
    </div>
  );
}

export default MyChildrenForm;

import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { InputLabel, TextField } from '@mui/material';

function InputField({
  label, required, disabled, hidden, multiline, maxRows, pattern, ...props
}) {
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;

  const handleChange = (event) => {
    const { value } = event.target;
    if ((pattern !== null && pattern.test(value)) || value === '') {
      setValue(value);
    }
    if (pattern === null) {
      setValue(value);
    }
  };

  return (
    <>
      <InputLabel
        required={required}
        disabled={disabled}
        hidden={hidden}
        className={meta.touched && Boolean(meta.error) ? 'Mui-error' : ''}
      >
        {label}
      </InputLabel>
      <TextField
        InputProps={{ disableUnderline: true }}
        fullWidth
        required={required}
        disabled={disabled}
        hidden={hidden}
        multiline={multiline}
        maxRows={maxRows}
        {...field}
        {...props}
        variant="filled"
        onChange={handleChange}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
      />
    </>
  );
}

InputField.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  multiline: PropTypes.bool,
  maxRows: PropTypes.number,
  pattern: PropTypes.shape(RegExp),
};

InputField.defaultProps = {
  label: '',
  required: false,
  disabled: false,
  hidden: false,
  multiline: false,
  maxRows: 4,
  pattern: null,
};

export default InputField;

import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '@mui/material';
import { logOut, adminLogIn, reset } from '../../components/User/services/authSlice';

export default function AdminLogin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(null);
  const { loading, message } = useSelector((state) => state.auth);
  const [searchParams] = useSearchParams();

  let searchParamToken = null;
  if (searchParams) {
    searchParamToken = searchParams.get('token')?.length > 0
      ? searchParams.get('token') : null;
  }

  useEffect(() => {
    dispatch(logOut());
    if (searchParamToken) {
      dispatch(adminLogIn({ token: searchParamToken }));
    } else {
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    switch (loading) {
      case 'failed':
        setErrorMessage(message);
        break;
      case 'pending':
        break;
      case 'succeeded':
        navigate('/profile');
        break;
      default:
    }
    dispatch(reset());
  }, [loading, message]);

  return (
    <div className="container container__wrap login col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
      <div className="row d-flex justify-content-center">
        {errorMessage && (
        <div className="py-5">
          <Alert severity="error" className="col">
            {errorMessage}
          </Alert>
        </div>
        )}
      </div>
    </div>
  );
}

import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import resources from './resources';

export const defaultLang = 'hu';

i18next
  .use(initReactI18next)
  .init({
    resources,
    lng: defaultLang,
    supportedLngs: ['hu', 'en'],
    nonExplicitSupportedLngs: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;

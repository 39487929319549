import React from 'react';
import {
  ListItem,
  List,
  ClickAwayListener,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

function AutocompleteSearchInput({
  value,
  handleChange,
  handleOnFocus,
  handleOnKeyDown,
  searchResults,
  handleItemClick,
  showOptions,
  handleClickAway,
}) {
  const { t } = useTranslation();

  const generateSearchList = () => searchResults.map((option) => (
    <ListItem
      className="autocomplete-search-list-item"
      key={option.id}
      value={option.id}
      title={option.name}
      onClick={(event) => handleItemClick(event)}
    >
      {option.name}
    </ListItem>
  ));

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className="autocomplete-search">
        <div className="row g-0">
          <TextField
            InputProps={{
              disableUnderline: true,
              inputProps: {
                style: {
                  paddingTop: 7,
                  paddingBottom: 7,
                },
              },
            }}
            placeholder={t('pages.events.searchTitle')}
            fullWidth
            variant="filled"
            name="text"
            value={value}
            onChange={handleChange}
            autoComplete="off"
            onFocus={handleOnFocus}
            onKeyDown={handleOnKeyDown}
          />
        </div>
        <div className="row g-0 position-relative">
          <List
            className={`autocomplete-search-list${searchResults.length > 0 && showOptions ? ' show' : ''}`}
          >
            {searchResults.length > 0 && showOptions && generateSearchList()}
          </List>
        </div>
      </div>
    </ClickAwayListener>
  );
}

AutocompleteSearchInput.propTypes = {
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleOnFocus: PropTypes.func.isRequired,
  handleOnKeyDown: PropTypes.func.isRequired,
  searchResults: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleItemClick: PropTypes.func.isRequired,
  showOptions: PropTypes.bool.isRequired,
  handleClickAway: PropTypes.func.isRequired,
};

export default AutocompleteSearchInput;

import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function CheckPolicies() {
  const { isAuthenticated, account } = useSelector((state) => state.auth);

  if (isAuthenticated && account.policyAccepted !== true
    && account.privacyPolicyAccepted !== true) {
    return <Navigate to="/policy-acceptance" />;
  }
}

export default CheckPolicies;

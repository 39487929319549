import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppBar, Toolbar } from '@mui/material';
import { ReactComponent as Logo } from './logo.svg';
import LanguageSwitcher from './components/Lang/LanguageSwitcher';
import AccountButton from './components/User/AccountButton';
import MenuButton from './components/MenuButton';

function CustomAppBar() {
  const { t } = useTranslation();
  const publicLinks = [
    {
      label: t('pages.events.title'),
      route: '/events',
    },
  ];
  const protectedLinks = [
    {
      label: t('pages.events.title'),
      route: '/events',
    },
    {
      label: t('pages.events.my'),
      route: '/my-events',
    },
    {
      label: t('pages.profile.button.logOut'),
      route: '/logout',
    },
  ];
  const { isAuthenticated } = useSelector((state) => state.auth);
  const [list, setList] = useState(publicLinks);

  useEffect(() => {
    if (isAuthenticated) {
      setList(protectedLinks);
    } else {
      setList(publicLinks);
    }
  }, [isAuthenticated, t]);

  return (
    <AppBar className="app-bar">
      <Toolbar className="toolbar justify-content-between">
        <Link className="topbar__logo" to="/">
          <Logo className="app-logo" />
        </Link>
        <div className="d-flex">
          <div className="contactInfo">
            <span>{t('common.contactInfo')}</span>
          </div>
        </div>
        <div className="d-flex">
          <LanguageSwitcher />
          <AccountButton isAuthenticated={isAuthenticated} />
          <MenuButton links={list} />
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default CustomAppBar;

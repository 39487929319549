import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';
import { persistStore } from 'reduxjs-toolkit-persist';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.css';
import './App.scss';
import { ThemeProvider } from '@mui/material/styles';
import store from './store';
import theme from './theme';
import Router from './Router';
import ScrollToTop from './ScrollToTop';
import i18next from './translations/i18n';

const persistor = persistStore(store);

function App() {
  const { i18n } = store.getState();

  useEffect(() => {
    i18next.changeLanguage(i18n.lang);
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <I18nextProvider i18n={i18next}>
              <ScrollToTop>
                <Router />
              </ScrollToTop>
            </I18nextProvider>
          </BrowserRouter>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;

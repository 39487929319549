import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { DialogContent, Button, FormHelperText } from '@mui/material';
import LoadingButton from '../../../LoadingButton';
import InputField from '../../InputField';
import SelectField from '../../../SelectField';
import { updateChild } from '../../services/authSlice';
import getPattern from '../../../../helpers/getPattern';

function UpdateChildForm({
  child, handleClose, errorMessage, allowed, isLoading,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const genderOptions = [
    {
      value: '0',
      label: t('child.genderOptions.boy'),
    },
    {
      value: '1',
      label: t('child.genderOptions.girl'),
    },
  ];

  const renderElement = (field) => {
    switch (field) {
      case 'id':
        return (
          <InputField
            name={field}
            label={t(`child.${field}`)}
            hidden
            disabled
          />
        );
      case 'connections':
      case 'languages':
        return null;
      case 'gender':
        return (
          <SelectField
            name={field}
            label={t(`child.${field}`)}
            options={genderOptions}
            required
            disabled={!allowed.includes(field)}
          />
        );
      case 'specialRequirements':
        return (
          <InputField
            name={field}
            label={t(`child.${field}`)}
            multiline
            disabled={!allowed.includes(field)}
          />
        );
      default:
        return (
          <InputField
            name={field}
            label={t(`child.${field}`)}
            required={['lastName', 'firstName', 'birthDate'].includes(field)}
            disabled={!allowed.includes(field)}
            pattern={getPattern(field)}
          />
        );
    }
  };

  return (
    <DialogContent>
      <div>
        <Formik
          initialValues={child}
          onSubmit={(values) => {
            const allowedData = allowed.reduce((formData, value) => (
              { ...formData, [value]: values[value] }
            ), {});
            const newData = {
              id: values.id,
              updatedData: allowedData,
            };
            dispatch(updateChild(newData));
          }}
        >
          <div className="col">
            <Form noValidate>
              <div className="row">
                {Object.keys(child).map((fieldName) => {
                  let smNum;
                  let mdNum;

                  switch (fieldName) {
                    case 'firstName':
                    case 'lastName':
                    case 'howToCall':
                    case 'parentName1':
                    case 'parentName2':
                      smNum = 6;
                      mdNum = 6;
                      break;
                    case 'birthDate':
                    case 'gender':
                      smNum = 6;
                      mdNum = 3;
                      break;
                    default:
                      smNum = 12;
                      mdNum = 12;
                  }

                  return (
                    <div className={`col-12 col-sm-${smNum} col-md-${mdNum} mb-4`} key={fieldName}>
                      {renderElement(fieldName)}
                    </div>
                  );
                })}
              </div>
              {errorMessage && (
              <div className="d-flex justify-content-center row">
                <div className="col-12 col-sm-auto col-md-auto mt-2">
                  <FormHelperText error>
                    {errorMessage}
                  </FormHelperText>
                </div>
              </div>
              )}
              <div className="d-flex justify-content-center row">
                <div className="col-12 col-sm-6 col-md-4 mt-4">
                  <Button
                    fullWidth
                    variant="contained"
                    type="button"
                    color="secondary"
                    onClick={handleClose}
                  >
                    {t('common.button.cancel')}
                  </Button>
                </div>
                <div className="col-12 col-sm-6 col-md-4 mt-4 mb-4">
                  <LoadingButton
                    fullWidth
                    variant="contained"
                    title={t('common.button.edit')}
                    type="submit"
                    loading={isLoading}
                  />
                </div>
              </div>
            </Form>
          </div>
        </Formik>
      </div>
    </DialogContent>
  );
}

UpdateChildForm.propTypes = {
  child: PropTypes.shape({}).isRequired,
  handleClose: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  allowed: PropTypes.arrayOf(PropTypes.string),
  isLoading: PropTypes.bool,
};

UpdateChildForm.defaultProps = {
  errorMessage: null,
  allowed: [],
  isLoading: false,
};

export default UpdateChildForm;

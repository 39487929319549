import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { format, parseISO, addMinutes } from 'date-fns';
import AlertBar from '../../AlertBar';
import { reset } from '../services/eventSlice';
import { getProfile } from '../../User/services/authSlice';
import CheckPolicies from '../../CheckPolicies';
import DynamicDialog from '../../User/Profile/DynamicDialog';
import DeleteConfirm from '../../User/Profile/Child/DeleteConfirm';
import getErrorMessage from '../../../helpers/getErrorMessage';
import MyEventCalendar from './MyEventCalendar';

function compareByStart(a, b) {
  if (a.start > b.start) {
    return 1;
  }
  if (a.start < b.start) {
    return -1;
  }
  return 0;
}

function MyEventList() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { lang } = useSelector((state) => state.i18n);
  const { account, token } = useSelector((state) => state.auth);
  const [view, setView] = useState('dayGridMonth');
  const [slots, setSlots] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dataForDialog, setDataForDialog] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const getName = (event) => {
    let eventName;
    if (lang === 'hu') {
      eventName = event.name;
    } else {
      eventName = event.nameEnglish;
    }

    if (eventName === null || eventName.length === 0) {
      eventName = event.name;
    }

    return eventName;
  };

  const getApplies = (calendarView) => {
    const applyArray = account.applies.map((apply) => {
      let eventUrl = `/events/${apply.event.id}`;
      let eventTitle = getName(apply.event);
      if (apply.child !== undefined) {
        eventTitle += ` (${apply.child.name})`;
        eventUrl += `?childId=${apply.child.id}`;
      }
      if (apply.isWaitingList) {
        eventTitle += ` - ${t('pages.events.apply.status.4')}`;
      }

      const newEvent = {
        id: apply.id,
        start: apply.applyDate,
        end: null,
        title: eventTitle,
        url: eventUrl,
        display: 'block',
        extendedProps: {
          eventId: apply.event.id,
          eventIntervalMinutes: apply.event.intervalMinutes,
          isWaitingList: apply.isWaitingList,
          simplifiedApplication: apply.event.simplifiedApplication,
          user: apply.parent,
          child: apply?.child,
        },
      };

      return newEvent;
    });

    if (calendarView === 'dayGridMonth') {
      const newArray = [];
      const sorted = applyArray.sort(compareByStart);
      sorted.forEach((e) => {
        const day = e.start.slice(0, 10);
        const index = newArray.findLastIndex((apply) => apply.start.indexOf(day) === 0
        && apply.url === e.url
        && apply.extendedProps.isWaitingList === e.extendedProps.isWaitingList);
        if (index >= 0) {
          const end = format(addMinutes(parseISO(e.start), e.extendedProps.eventIntervalMinutes), 'yyyy-MM-dd HH:mm');
          newArray[index].end = end;
        } else {
          newArray.push(e);
        }
      });

      return newArray;
    }

    return applyArray;
  };

  useEffect(() => {
    const newEventArray = getApplies(view);
    setSlots(newEventArray);
  }, [lang]);

  useEffect(() => {
    const newEventArray = getApplies(view);
    setSlots(newEventArray);
  }, [account.applies]);

  useEffect(() => {
    if (token !== null) {
      dispatch(getProfile(token));
      dispatch(reset());
      const newEventArray = getApplies(view);
      setSlots(newEventArray);
    }
  }, [token]);

  useEffect(() => {
    const newEventArray = getApplies(view);
    setSlots(newEventArray);
  }, [view]);

  const handleRemove = async (formData) => {
    setErrorMessage(null);
    setIsLoading(true);

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/application/remove`, formData);
      dispatch(getProfile());
      setIsLoading(false);
      setOpenDialog(false);
    } catch (err) {
      setErrorMessage(getErrorMessage(err));
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
    setDataForDialog({});
  };

  const handleClickOpen = (data) => {
    setErrorMessage(null);
    setDataForDialog(data);
    setOpenDialog(true);
  };

  const handleItem = (formData) => {
    handleClickOpen(formData);
  };

  const getContent = () => ((account.applies?.length > 0)
    ? (
      <>
        <DynamicDialog
          open={openDialog}
          handleClose={handleClose}
          type="delete"
        >
          <DeleteConfirm
            title={t('pages.events.apply.deletePrompt')}
            handleClose={handleClose}
            handleClickConfirm={() => {
              handleRemove(dataForDialog);
            }}
            error={errorMessage}
            isLoading={isLoading}
          />
        </DynamicDialog>
        <MyEventCalendar
          lang={lang}
          slots={slots}
          handleItem={handleItem}
          view={view}
          setView={setView}
        />
      </>
    ) : (
      <div className="m-3 d-flex justify-content-center">
        {t('common.noContent')}
      </div>
    ));

  return (
    <>
      <CheckPolicies />
      <div className="container container__wrap my-events col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
        <AlertBar />
        <div className="row d-flex justify-content-center">
          {getContent()}
        </div>
      </div>
    </>
  );
}

export default MyEventList;

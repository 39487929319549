import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { FormHelperText } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import LoadingButton from '../../LoadingButton';
import PolicyPart from '../SignUp/PolicyPart';
import { updateAccount, reset } from '../services/authSlice';

function PolicyAcceptanceForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { loading, message, account } = useSelector((state) => state.auth);

  const initialValues = {
    policyAccepted: false,
    privacyPolicyAccepted: false,
    newsletter: false,
  };

  const validationSchema = yup.object({
    policyAccepted: yup
      .bool()
      .test('is-true', t('error.acceptPolicy'), (value) => value === true),
    privacyPolicyAccepted: yup
      .bool()
      .test('is-true', t('error.acceptPrivacyPolicy'), (value) => value === true),
  });

  useEffect(() => {
    if (account.policyAccepted === true
      && account.privacyPolicyAccepted === true) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    if (!isSaving) {
      return;
    }
    switch (loading) {
      case 'failed':
        setErrorMessage(message);
        setIsLoading(false);
        break;
      case 'pending':
        setIsLoading(true);
        break;
      case 'succeeded':
        setErrorMessage(null);
        setIsSaving(false);
        setIsLoading(false);
        navigate('/');
        break;
      default:
    }
    dispatch(reset());
  }, [loading, message]);

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          setIsSaving(true);
          dispatch(updateAccount(values));
        }}
      >
        <div className="col">
          <Form noValidate>
            <PolicyPart />
            {errorMessage && (
              <div className="d-flex justify-content-center row">
                <div className="col-12 col-sm-auto col-md-auto mt-2">
                  <FormHelperText error>
                    {errorMessage}
                  </FormHelperText>
                </div>
              </div>
            )}
            <div className="d-flex justify-content-center row">
              <div className="col-12 col-sm-6 col-md-4 mt-4 mb-4">
                <LoadingButton
                  fullWidth
                  variant="contained"
                  title={t('common.button.save')}
                  type="submit"
                  loading={isLoading}
                />
              </div>
            </div>
          </Form>
        </div>
      </Formik>
    </div>
  );
}

export default PolicyAcceptanceForm;

import React, { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import {
  Box, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText,
} from '@mui/material';
import { Menu } from '@mui/icons-material';
import { logOut } from './User/services/authSlice';

function MenuButton({ links }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const getProps = (route) => {
    const props = {};
    switch (route) {
      case '/logout':
        props.onClick = () => {
          dispatch(logOut());
        };
        break;
      default:
        props.component = Link;
        props.to = route;
        props.selected = (window.location.pathname === route);
    }

    return props;
  };

  const [state, setState] = useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {links.map(({ label, route }) => (
          <ListItem key={label} disablePadding>
            <ListItemButton
              {...getProps(route)}
            >
              <ListItemText
                primary={label}
                primaryTypographyProps={{ fontWeight: 325, fontSize: 16 }}
                sx={{ textAlign: 'right' }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      {['right'].map((anchor) => (
        <Fragment key={anchor}>
          <IconButton
            size="large"
            aria-label="menu"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={toggleDrawer(anchor, true)}
            sx={{
              color: theme.palette.custom.main,
              '&:hover': {
                backgroundColor: 'inherit',
              },
            }}
          >
            <Menu />
          </IconButton>
          <Drawer
            className="sidebar-wrapper"
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            PaperProps={{
              sx: {
                boxShadow: 'none',
              },
            }}
          >
            {list(anchor)}
          </Drawer>
        </Fragment>
      ))}
    </div>
  );
}

MenuButton.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default MenuButton;

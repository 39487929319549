import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import AppWrapper from './AppWrapper';
import CustomAppBar from './CustomAppBar';
import SignUp from './pages/signup/index';
import Login from './pages/login/index';
import Forgot from './pages/forgot/index';
import ForgotPassword from './pages/forgot-password/index';
import AdminLogin from './pages/login/AdminLogin';
import Profile from './pages/profile/index';
import Events from './pages/events';
import PolicyAcceptance from './pages/policy-acceptance/index';
import EventPage from './components/Event/EventPage';
import MyEventList from './components/Event/MyEvents/MyEventList';

function Router() {
  return (
    <AppWrapper>
      <main>
        <CustomAppBar />
        <Routes>
          <Route path="/" element={<Navigate to="/events" />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/forgot-password/:token" element={<ForgotPassword />} />
          <Route path="/admin-login" element={<AdminLogin />} />
          <Route path="/events" element={<Events />} />
          <Route path="/events/:id" element={<EventPage />} />
          <Route path="/" element={<ProtectedRoute />}>
            <Route path="/profile" element={<Profile />} />
            <Route path="/my-events" element={<MyEventList />} />
            <Route path="/policy-acceptance" element={<PolicyAcceptance />} />
          </Route>
        </Routes>
      </main>
    </AppWrapper>
  );
}

export default Router;

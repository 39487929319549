import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { logOut } from './components/User/services/authSlice';

function AppWrapper({ children }) {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { lang } = useSelector((state) => state.i18n);

  axios.interceptors.response.use((response) => response, (error) => {
    if (error.response.status === 401) {
      dispatch(logOut());
    }
    return Promise.reject(error);
  });

  axios.defaults.headers.locale = lang;

  useEffect(() => {
    if (token) {
      axios.defaults.headers['X-AUTH-TOKEN'] = token;
    } else {
      delete axios.defaults.headers['X-AUTH-TOKEN'];
    }
  }, [token]);

  return (
    <div className="wrapper">
      {children}
    </div>
  );
}

AppWrapper.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AppWrapper;

import { isValid, parse } from 'date-fns';

export function isValidDate(value) {
  const parsedDate = parse(value, 'yyyy-MM-dd', new Date());

  return isValid(parsedDate);
}

export function isValidValue(value) {
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  if (!dateRegex.test(value)) return false;

  const date = new Date(value);
  if (date.getTime() - date.getTime() === 0) {
    return true;
  }
  return false;
}

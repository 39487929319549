import React from 'react';
import EventList from '../../components/Event/EventList';
import AlertBar from '../../components/AlertBar';
import CheckPolicies from '../../components/CheckPolicies';

export default function Events() {
  return (
    <>
      <CheckPolicies />
      <div className="container__wrap events">
        <div className="container col-12 col-sm-12 col-md-10 col-lg-10 col-xl-8 col-xxl-8">
          <AlertBar />
        </div>
        <EventList />
      </div>
    </>
  );
}

import translationHu from './hu/translation.json';
import translationEn from './en/translation.json';

export default {
  hu: {
    translation: translationHu,
  },
  en: {
    translation: translationEn,
  },
};

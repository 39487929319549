import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Alert } from '@mui/material';

function AlertBar() {
  const { t } = useTranslation();
  const { isAuthenticated, account } = useSelector((state) => state.auth);

  const getContent = () => {
    if (isAuthenticated && !account.isActive) {
      return (
        <div className="row d-flex justify-content-center mb-4">
          <div className="col">
            <div className="row">
              <div className="col">
                <Alert severity="warning">
                  {t('alert.isNotActive')}
                </Alert>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  return getContent();
}

export default AlertBar;

import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import addValidation from './addValidation';

const createLogInValidationSchema = () => {
  const { t } = useTranslation();
  return (yup.object({
    email: addValidation('email'),
    password: yup
      .string()
      .required(t('error.requiredField')),
  }));
};

export default createLogInValidationSchema;

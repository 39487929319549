import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import localeHu from 'date-fns/locale/hu';
import localeEn from 'date-fns/locale/en-GB';

function AvailablePart({ event }) {
  const { t } = useTranslation();
  const { lang } = useSelector((state) => state.i18n);
  const locale = lang === 'hu' ? localeHu : localeEn;

  const createCycleList = () => {
    const start = format(new Date(event.cycleDateStart), 'PPP', { locale });
    const end = format(new Date(event.cycleDateEnd), 'PPP', { locale });
    const cycleDateRange = `${start}-${end}`;
    const cycleDates = Object.entries(event.cycleDates).map((item) => {
      const openingHours = item[1].openingHours.filter(
        (time) => (time.open !== '' && time.open !== null) || (time.close !== '' && time.close !== null),
      ).map((time) => `${time.open}-${time.close}`);
      return {
        ...item[1],
        openingHours,
      };
    }).filter((day) => day.openingHours.length > 0);

    return (
      <div className="row mb-4">
        <div className="page-title col-12"><span>{t('pages.events.apply.available')}</span></div>
        <div className="col-12">
          <div className="list-group">
            <div className="list-group-item">
              <div className="d-flex w-100">
                <h5 className="mb-1">{cycleDateRange}</h5>
              </div>
              {cycleDates.map((day) => (
                <p key={day.day} className="mb-1">
                  <span>{`${lang === 'hu' ? day.dayText : day.day}: `}</span>
                  {day.openingHours.join(', ')}
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const createUniqueList = () => {
    const uniqueDates = event.uniqueDates.map((obj) => {
      const day = format(new Date(obj.date), 'PPPP', { locale });
      const timeRange = `${obj.open}-${obj.close}`;

      return {
        day,
        openingHours: [timeRange],
      };
    });

    const result = Object.values(uniqueDates.reduce((r, o) => {
      const clone = { ...r };
      if (!r[o.day]) {
        clone[o.day] = { ...o };
      } else {
        clone[o.day].openingHours = [].concat(clone[o.day].openingHours, o.openingHours);
      }
      return clone;
    }, {}));

    return (
      <div className="row mb-4">
        <div className="page-title col-12"><span>{t('pages.events.apply.available')}</span></div>
        <div className="col-12">
          <div className="list-group">
            {result.map((day) => (
              <div key={day.day} className="list-group-item">
                <div className="d-flex w-100">
                  <h5 className="mb-1">{day.day}</h5>
                </div>
                <p className="mb-1">{day.openingHours.join(', ')}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const getList = () => {
    switch (event.dateTypeNumber) {
      case '0':
        return createUniqueList();
      default:
        return createCycleList();
    }
  };

  return getList();
}

AvailablePart.propTypes = {
  event: PropTypes.shape({}).isRequired,
};

export default AvailablePart;

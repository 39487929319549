import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import {
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  FilledInput,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

function PasswordField({ label, required, ...props }) {
  const [field, meta] = useField(props);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <>
      <InputLabel
        required={required}
        className={meta.touched && Boolean(meta.error) ? 'Mui-error' : ''}
      >
        {label}
      </InputLabel>
      <FilledInput
        disableUnderline
        autoComplete="new-password"
        fullWidth
        required={required}
        {...field}
        {...props}
        type={showPassword ? 'text' : 'password'}
        variant="filled"
        error={meta.touched && Boolean(meta.error)}
        endAdornment={(
          <InputAdornment position="end">
            <IconButton
              onClick={() => handleClickShowPassword()}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        )}
      />
      <FormHelperText error={meta.touched && Boolean(meta.error)}>
        {meta.touched && meta.error}
      </FormHelperText>
    </>
  );
}

PasswordField.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
};

PasswordField.defaultProps = {
  label: '',
  required: false,
};

export default PasswordField;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import InputField from '../InputField';
import PasswordField from '../PasswordField';
import SwitchField from '../SwitchField';
import getPattern from '../../../helpers/getPattern';

function UserPart({ readOnly, newsletterOn }) {
  const { t } = useTranslation();
  const { values } = useFormikContext();
  let deny = ['languages', 'children', 'policyAccepted', 'privacyPolicyAccepted', 'newsletter'];
  if (newsletterOn) {
    deny = deny.filter((item) => item !== 'newsletter');
  }

  const fields = Object.keys(values).filter((field) => !deny.includes(field));

  const renderElement = (fieldName) => {
    switch (fieldName) {
      case 'languages':
      case 'children':
      case 'policyAccepted':
      case 'privacyPolicyAccepted':
        return null;
      case 'password':
      case 'passwordAgain':
        return (
          <PasswordField
            name={fieldName}
            label={t(`user.${fieldName}`)}
            required
          />
        );
      case 'newsletter':
        return (
          <SwitchField
            name={fieldName}
            label={t(`user.${fieldName}`)}
          />
        );
      default:
        return (
          <InputField
            name={fieldName}
            label={t(`user.${fieldName}`)}
            required={!['floor', 'door'].includes(fieldName)}
            disabled={readOnly.includes(fieldName)}
            pattern={getPattern(fieldName)}
          />
        );
    }
  };

  return (
    <div className="row">
      {fields.map((fieldName) => {
        let smNum;
        let mdNum;
        switch (fieldName) {
          case 'userName':
          case 'email':
          case 'password':
          case 'passwordAgain':
          case 'firstName':
          case 'lastName':
          case 'phoneNumber':
          case 'newsletter':
            smNum = 6;
            mdNum = 6;
            break;
          case 'zipCode':
          case 'houseNumber':
          case 'floor':
          case 'door':
            smNum = 4;
            mdNum = 2;
            break;
          case 'street':
            smNum = 8;
            mdNum = 4;
            break;
          default:
            smNum = 12;
            mdNum = 12;
        }

        return (
          <div className={`col-12 col-sm-${smNum} col-md-${mdNum} mb-4`} key={fieldName}>
            {renderElement(fieldName)}
          </div>
        );
      })}
    </div>
  );
}

UserPart.propTypes = {
  readOnly: PropTypes.arrayOf(PropTypes.string),
  newsletterOn: PropTypes.bool,
};

UserPart.defaultProps = {
  readOnly: [],
  newsletterOn: false,
};

export default UserPart;

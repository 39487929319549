import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, CircularProgress } from '@mui/material';

function LoadingButton({
  title,
  loading,
  ...props
}) {
  const classes = {
    default: {
      color: 'white',
    },
    loading: {
      cursor: 'default',
      color: '#2E3092',
      '&:hover': {
        cursor: 'default',
        backgroundColor: '#2E3092',
      },
    },
  };

  return (
    <Box sx={{ p: '0', position: 'relative' }}>
      <Button
        sx={(loading) ? classes.loading : classes.default}
        {...props}
      >
        {title}
      </Button>
      {loading && (
        <div className="white-spinner">
          <CircularProgress
            size={24}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
              color: 'white',
            }}
          />
        </div>
      )}
    </Box>
  );
}

LoadingButton.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

LoadingButton.defaultProps = {
  title: '',
  loading: false,
  disabled: false,
};

export default LoadingButton;

import { createTheme } from '@mui/material/styles';

const primaryMain = '#2E3092';
const secondaryMain = '#F2F3F8';
const customMain = '#333';

const theme = createTheme({
  typography: {
    fontFamily: 'Gotham Rounded, Roboto, sans-serif',
  },
  palette: {
    primary: {
      main: primaryMain,
    },
    secondary: {
      main: secondaryMain,
      contrastText: primaryMain,
    },
    custom: {
      main: customMain,
    },
  },
  shape: {
    borderRadius: 30,
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontWeight: 300,
          fontSize: 14,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontWeight: 300,
          fontSize: 14,
          color: primaryMain,
          paddingLeft: 28,
          marginBottom: 8,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderBottomLeftRadius: 30,
          borderBottomRightRadius: 30,
        },
        input: {
          color: primaryMain,
          fontWeight: 350,
          paddingTop: 14,
          paddingLeft: 28,
          paddingBottom: 14,
          paddingRight: 28,
          ':-webkit-autofill': {
            borderBottomLeftRadius: 'inherit',
            borderBottomRightRadius: 'inherit',
          },
        },
        multiline: {
          color: primaryMain,
          fontWeight: 'bolder',
          paddingTop: 0,
          paddingLeft: 0,
          paddingBottom: 0,
          paddingRight: 0,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontWeight: 300,
          marginLeft: 14,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'inherit',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: primaryMain,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          fontWeight: 325,
        },
        select: {
          '&:focus': {
            borderBottomLeftRadius: 'inherit',
            borderBottomRightRadius: 'inherit',
            borderTopLeftRadius: 'inherit',
            borderTopRightRadius: 'inherit',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontWeight: 325,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          color: primaryMain,
          fontWeight: 300,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: primaryMain,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        textColorPrimary: {
          fontWeight: 325,
          color: primaryMain,
          backgroundColor: secondaryMain,
          textTransform: 'none',
          borderRadius: 30,
          minHeight: 1,
          marginBottom: 20,
          marginRight: 24,
          paddingTop: 6,
          paddingLeft: 20,
          paddingBottom: 6,
          paddingRight: 20,
          '&.Mui-selected': {
            color: 'white',
            backgroundColor: primaryMain,
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          // borderBottom: '2px solid',
          // borderColor: customMain,
          boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontWeight: 350,
          fontSize: 18,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          fontWeight: 350,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          fontWeight: 325,
        },
      },
    },
  },
});

export default theme;

import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { DialogContent } from '@mui/material';
import { addConnection } from '../../services/authSlice';
import ConnectionForm from './ConnectionForm';
import connectionValidationSchema from '../../../../helpers/user/connectionValidationSchema';

function AddConnection({
  init, handleClose, errorMessage, allowed, isLoading,
}) {
  const dispatch = useDispatch();
  const validationSchema = connectionValidationSchema();

  return (
    <DialogContent>
      <div>
        <Formik
          initialValues={init}
          validationSchema={validationSchema}
          onSubmit={(data) => {
            const allowedData = allowed.reduce((formData, value) => (
              { ...formData, [value]: data[value] }
            ), {});
            const newData = {
              id: data.id,
              addedData: allowedData,
            };
            dispatch(addConnection(newData));
          }}
        >
          <ConnectionForm
            handleClose={handleClose}
            errorMessage={errorMessage}
            isLoading={isLoading}
          />
        </Formik>
      </div>
    </DialogContent>
  );
}

AddConnection.propTypes = {
  init: PropTypes.shape({}).isRequired,
  handleClose: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  allowed: PropTypes.arrayOf(PropTypes.string),
  isLoading: PropTypes.bool,
};

AddConnection.defaultProps = {
  errorMessage: null,
  allowed: ['lastName', 'firstName', 'phoneNumber', 'relationshipType'],
  isLoading: false,
};

export default AddConnection;

import React from 'react';
import PolicyAcceptanceForm from '../../components/User/Profile/PolicyAcceptanceForm';

export default function PolicyAcceptance() {
  return (
    <div className="container container__wrap policy-acceptance col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
      <div className="row d-flex justify-content-center">
        <PolicyAcceptanceForm />
      </div>
    </div>
  );
}

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Card, CardImg, CardBody, CardTitle, CardText,
} from 'reactstrap';

const noImage = `${process.env.PUBLIC_URL}/images/no_photo.png`;

const EventCard = React.forwardRef(({ href, event }, innerref) => {
  const { lang } = useSelector((state) => state.i18n);

  const getName = () => {
    let eventName;
    if (lang === 'hu') {
      eventName = event.name;
    } else {
      eventName = event.nameEnglish;
    }

    if (eventName === null || eventName.length === 0) {
      eventName = event.name;
    }

    return eventName;
  };

  const getShortDescription = () => {
    let eventShortDescription;
    if (lang === 'hu') {
      eventShortDescription = event.shortDescription;
    } else {
      eventShortDescription = event.shortDescriptionEnglish;
    }

    if (eventShortDescription === null || eventShortDescription.length === 0) {
      eventShortDescription = event.shortDescription;
    }

    return eventShortDescription;
  };

  const getTimes = () => {
    const times = event.times.map((time) => `${time.open}-${time.close}`);

    return times.join(' ');
  };

  return (
    <Link
      innerref={innerref}
      to={{
        pathname: href,
      }}
    >
      <Card className="mb-3 mb-sm-0" style={{ cursor: 'pointer' }}>
        <CardImg
          alt={getName()}
          src={event.image ? event.image : noImage}
        />
        <CardBody>
          <CardTitle tag="div" className="w-100">
            <span>{getName()}</span>
          </CardTitle>
          <CardText tag="div" className="event-description">
            <span>{getShortDescription()}</span>
          </CardText>
          <CardText tag="div" className="event-time">
            <span>{getTimes()}</span>
          </CardText>
        </CardBody>
      </Card>
    </Link>
  );
});

function EventLink({ href, event }) {
  return (
    <EventCard event={event} href={href} />
  );
}

EventLink.propTypes = {
  href: PropTypes.string,
  event: PropTypes.shape({}),
};

EventLink.defaultProps = {
  href: null,
  event: null,
};

EventCard.propTypes = {
  href: PropTypes.string,
  event: PropTypes.shape({
    image: PropTypes.string,
    name: PropTypes.string.isRequired,
    nameEnglish: PropTypes.string,
    shortDescription: PropTypes.string,
    shortDescriptionEnglish: PropTypes.string,
    day: PropTypes.string.isRequired,
    open: PropTypes.string.isRequired,
    close: PropTypes.string.isRequired,
    times: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }),
};

EventCard.defaultProps = {
  href: null,
  event: null,
};

export default EventLink;

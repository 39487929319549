import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import LoadingButton from '../LoadingButton';

function FormNavigation({
  hasPrevious, onBackClick, isLastStep, isLoading,
}) {
  const { t } = useTranslation();

  return (
    <div className="d-flex justify-content-center row">
      {hasPrevious && (
      <div className="col-12 col-sm-6 col-md-4 mt-4">
        <Button
          fullWidth
          variant="contained"
          type="button"
          color="secondary"
          onClick={onBackClick}
        >
          {t('common.button.back')}
        </Button>
      </div>
      )}
      <div className="col-12 col-sm-6 col-md-4 mt-4 mb-4">
        <LoadingButton
          fullWidth
          variant="contained"
          title={isLastStep ? t('pages.signUp.part2.submit') : t('common.button.next')}
          type="submit"
          loading={isLoading}
        />
      </div>
    </div>
  );
}

FormNavigation.propTypes = {
  hasPrevious: PropTypes.bool.isRequired,
  onBackClick: PropTypes.func.isRequired,
  isLastStep: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default FormNavigation;

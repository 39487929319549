import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getUserData,
  postLogInData,
  postTokenData,
  postSignUpData,
  putUpdateUser,
  putUpdatePassword,
  putUpdateChild,
  deleteDeleteChild,
  putUpdateConnection,
  deleteDeleteConnection,
  postChildAddConnection,
  postAddChild,
} from './authService';
import getErrorMessage from '../../../helpers/getErrorMessage';

const initialState = {
  token: null,
  isAuthenticated: false,
  isAdminLogin: false,
  loading: 'idle',
  message: null,
  account: null,
};

export const logOut = createAction('auth/logOut');

export const register = createAsyncThunk('auth/register', async (user, thunkAPI) => {
  try {
    return await postSignUpData(user);
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return thunkAPI.rejectWithValue(errorMessage);
  }
});

export const logIn = createAsyncThunk('auth/logIn', async (user, thunkAPI) => {
  try {
    const logInData = await postLogInData(user);
    const profileData = await getUserData(logInData.token);
    return {
      token: logInData.token,
      profileData,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return thunkAPI.rejectWithValue(errorMessage);
  }
});

export const adminLogIn = createAsyncThunk('auth/adminLogIn', async (data, thunkAPI) => {
  try {
    const tokenData = await postTokenData(data);
    const profileData = await getUserData(tokenData.token);
    return {
      token: tokenData.token,
      profileData,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return thunkAPI.rejectWithValue(errorMessage);
  }
});

export const getProfile = createAsyncThunk('auth/getProfile', async (token, thunkAPI) => {
  try {
    const profileData = await getUserData(token);
    return {
      profileData,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return thunkAPI.rejectWithValue(errorMessage);
  }
});

export const updateAccount = createAsyncThunk('auth/updateAccount', async (updatedData, thunkAPI) => {
  try {
    const updatedUser = await putUpdateUser(updatedData);
    return {
      account: updatedUser,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return thunkAPI.rejectWithValue(errorMessage);
  }
});

export const updatePassword = createAsyncThunk('auth/updatePassword', async (updatedData, thunkAPI) => {
  try {
    const updatedPassword = await putUpdatePassword(updatedData);
    return {
      data: updatedPassword.data,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return thunkAPI.rejectWithValue(errorMessage);
  }
});

export const addChild = createAsyncThunk('auth/addChild', async (data, thunkAPI) => {
  try {
    return await postAddChild(data);
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return thunkAPI.rejectWithValue(errorMessage);
  }
});

export const updateChild = createAsyncThunk('auth/updateChild', async (data, thunkAPI) => {
  try {
    const { id, updatedData } = data;
    await putUpdateChild(id, updatedData);
    const profileData = await getUserData();
    return {
      profileData,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return thunkAPI.rejectWithValue(errorMessage);
  }
});

export const deleteChild = createAsyncThunk('auth/deleteChild', async (id, thunkAPI) => {
  try {
    await deleteDeleteChild(id);
    const profileData = await getUserData();
    return {
      profileData,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return thunkAPI.rejectWithValue(errorMessage);
  }
});

export const updateConnection = createAsyncThunk('auth/updateConnection', async (data, thunkAPI) => {
  try {
    const { id, updatedData } = data;
    await putUpdateConnection(id, updatedData);
    const profileData = await getUserData();
    return {
      profileData,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return thunkAPI.rejectWithValue(errorMessage);
  }
});

export const deleteConnection = createAsyncThunk('auth/deleteConnection', async (id, thunkAPI) => {
  try {
    await deleteDeleteConnection(id);
    const profileData = await getUserData();
    return {
      profileData,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return thunkAPI.rejectWithValue(errorMessage);
  }
});

export const addConnection = createAsyncThunk('auth/addConnection', async (data, thunkAPI) => {
  try {
    const { id, addedData } = data;
    await postChildAddConnection(id, addedData);
    const profileData = await getUserData();
    return {
      profileData,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return thunkAPI.rejectWithValue(errorMessage);
  }
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: (state) => {
      state.loading = 'idle';
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(register.fulfilled, (state) => {
        state.loading = 'succeeded';
      })
      .addCase(register.rejected, (state, action) => {
        state.loading = 'failed';
        state.message = action.payload;
      })
      .addCase(logIn.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(logIn.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.token = action.payload.token;
        state.account = action.payload.profileData;
        state.isAuthenticated = true;
      })
      .addCase(logIn.rejected, (state, action) => {
        state.loading = 'failed';
        state.message = action.payload;
        state.token = null;
      })
      .addCase(adminLogIn.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(adminLogIn.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.token = action.payload.token;
        state.account = action.payload.profileData;
        state.isAuthenticated = true;
        state.isAdminLogin = true;
      })
      .addCase(adminLogIn.rejected, (state, action) => {
        state.loading = 'failed';
        state.message = action.payload;
        state.token = null;
      })
      .addCase(updateAccount.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(updateAccount.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.account = action.payload.account;
      })
      .addCase(updateAccount.rejected, (state, action) => {
        state.loading = 'failed';
        state.message = action.payload;
      })
      .addCase(updatePassword.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(updatePassword.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.message = action.payload.data;
      })
      .addCase(updatePassword.rejected, (state, action) => {
        state.loading = 'failed';
        state.message = action.payload;
      })
      .addCase(getProfile.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.account = action.payload.profileData;
      })
      .addCase(getProfile.rejected, (state, action) => {
        state.loading = 'failed';
        state.message = action.payload;
      })
      .addCase(updateChild.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(updateChild.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.account = action.payload.profileData;
      })
      .addCase(updateChild.rejected, (state, action) => {
        state.loading = 'failed';
        state.message = action.payload;
      })
      .addCase(deleteChild.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(deleteChild.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.account = action.payload.profileData;
      })
      .addCase(deleteChild.rejected, (state, action) => {
        state.loading = 'failed';
        state.message = action.payload;
      })
      .addCase(updateConnection.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(updateConnection.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.account = action.payload.profileData;
      })
      .addCase(updateConnection.rejected, (state, action) => {
        state.loading = 'failed';
        state.message = action.payload;
      })
      .addCase(deleteConnection.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(deleteConnection.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.account = action.payload.profileData;
      })
      .addCase(deleteConnection.rejected, (state, action) => {
        state.loading = 'failed';
        state.message = action.payload;
      })
      .addCase(addConnection.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(addConnection.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.account = action.payload.profileData;
      })
      .addCase(addConnection.rejected, (state, action) => {
        state.loading = 'failed';
        state.message = action.payload;
      })
      .addCase(addChild.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(addChild.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.account = action.payload;
      })
      .addCase(addChild.rejected, (state, action) => {
        state.loading = 'failed';
        state.message = action.payload;
      });
  },
});

export const { reset } = authSlice.actions;
export default authSlice.reducer;

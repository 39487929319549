import React, {
  useState, useEffect, useRef, createRef,
} from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { format, parseISO, addMinutes } from 'date-fns';
import { useFormikContext } from 'formik';
import {
  Alert, Button, CircularProgress, IconButton,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import huLocale from '@fullcalendar/core/locales/hu';
import enLocale from '@fullcalendar/core/locales/en-gb';
import { getColorClass } from '../../helpers/applyHelper';
import { getProfile } from '../User/services/authSlice';
import getErrorMessage from '../../helpers/getErrorMessage';
import CalendarLegend from '../CalendarLegend';
import LoadingButton from '../LoadingButton';
import AlertDialog from '../AlertDialog';

function CalendarPart({ event, childId }) {
  const calendarRef = createRef();
  const ref = useRef();
  const { t } = useTranslation();
  const { values, setFieldValue, setValues } = useFormikContext();
  const dispatch = useDispatch();
  const { lang } = useSelector((state) => state.i18n);
  const { selected } = useSelector((state) => state.event);
  const [initialDate, setInitialDate] = useState(selected?.day);
  const [initialDates, setInitialDates] = useState([]);
  const [headerToolbar, setHeaderToolbar] = useState({
    left: '',
    center: 'title',
    right: '',
  });
  const [slots, setSlots] = useState([]);
  const [applications, setApplications] = useState([]);
  const [slotMinTime, setSlotMinTime] = useState('00:00');
  const [slotMaxTime, setSlotMaxTime] = useState('24:00');
  const [errorMessage, setErrorMessage] = useState(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDialogAgree, setIsDialogAgree] = useState(false);
  const queryParams = childId !== null ? `?eventId=${event.id}&childId=${childId}` : `?eventId=${event.id}`;
  const searchAsObject = Object.fromEntries(
    new URLSearchParams(queryParams),
  );
  ['eventId', 'childId'].forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(searchAsObject, key)) {
      searchAsObject[key] = parseInt(searchAsObject[key], 10);
    }
  });

  const slotPropsObject = {
    slotLabelFormat: {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    },
    slotMinTime,
    slotMaxTime,
  };
  const interval = parseInt(event.interval.value, 10);

  const fetchSlots = (async (postData) => {
    let current;
    let isTemporary = false;
    let isIn = false;

    await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/application/slots`, postData)
      .then((res) => {
        const newArray = Object.entries(res.data).map((slot) => {
          if (Object.prototype.hasOwnProperty.call(postData, 'applications')) {
            current = postData.applications.findLast((a) => a.date === slot[0]);
            isTemporary = current !== undefined;
          }
          if (slot[0].indexOf(initialDate) === 0) {
            isIn = true;
          }
          const endSlote = format(addMinutes(parseISO(slot[0]), interval), 'yyyy-MM-dd HH:mm');

          return {
            start: slot[0],
            end: endSlote,
            display: 'background',
            extendedProps: {
              status: slot[1],
              isTemporary,
              isRemoval: isTemporary && current.isRemoval === 1,
            },
          };
        });
        const startSlotTimes = newArray.map((item) => format(parseISO(item.start), 'HH:mm'));
        const endSlotTimes = newArray.map((item) => format(parseISO(item.end), 'HH:mm'));

        setSlots(newArray);
        setInitialDate(!isIn && newArray.length > 0 ? format(parseISO(newArray[0].start), 'yyyy-MM-dd') : initialDate);
        if (newArray.length > 0) {
          const newArrayDates = newArray.map((item) => format(parseISO(item.start), 'yyyy-MM-dd'));
          const uniqueArrayDates = [...new Set(newArrayDates)];
          setInitialDates(uniqueArrayDates);
        }
        setSlotMinTime(startSlotTimes.length > 0 ? startSlotTimes.sort()[0] : '00:00');
        setSlotMaxTime(endSlotTimes.length > 0 ? endSlotTimes.sort().reverse()[0] : '24:00');
        ref.current = true;
      }).catch((err) => {
        setErrorMessage(getErrorMessage(err));
        ref.current = true;
      });
  });

  const getFilteredSlots = (data) => {
    const dateSource = data.date instanceof Date ? data.date : parseISO(data.date);
    const day = format(dateSource, 'yyyy-MM-dd');
    return slots.filter((slot) => slot.start.indexOf(day) === 0
      && [3, 4].includes(slot.extendedProps.status));
  };

  const handleLasts = (application) => {
    const lastIndex = applications.findLastIndex((a) => a.date === application.date);
    if (applications.length > 0 && applications.length - 1 === lastIndex) {
      applications.splice(lastIndex, 1);
    } else {
      applications.push(application);
    }

    return applications;
  };

  const onConfirm = () => {
    setIsDialogAgree(true);
    setIsModalOpen(false);
  };

  const handleWarningPopup = (applicationTime) => {
    if (event.id !== 107) return;
    if (isDialogAgree) return;
    if (applicationTime >= '13:00' && applicationTime < '15:00') {
      setIsModalOpen(true);
    }
  };

  const handleApplications = (slot, isRemoveDay = false) => {
    let filtered = [];
    let filteredFirst;
    let filteredLast;
    const slotStart = slot.start instanceof Date ? slot.start : parseISO(slot.start);
    const slotStatus = slot.extendedProps.status;
    const timeSlot = format(slotStart, 'yyyy-MM-dd HH:mm');
    const time = format(slotStart, 'HH:mm');
    switch (slotStatus) {
      case 1:
        handleWarningPopup(time);
        handleLasts({ date: timeSlot });
        setApplications(applications);
        return applications;
      case 2:
        handleWarningPopup(time);
        handleLasts({ date: timeSlot, isWaitingList: 1 });
        setApplications(applications);
        return applications;
      case 3:
      case 4:
        if (isRemoveDay) {
          handleLasts({ date: timeSlot, isRemoval: 1 });
          setApplications(applications);
        }
        filtered = getFilteredSlots({ date: timeSlot, isRemoval: 1 });
        filteredFirst = filtered.findIndex((f) => f.start === timeSlot);
        filteredLast = filtered.findLastIndex((f) => f.start === timeSlot);
        if (
          !isRemoveDay && filtered.length > 0
          && (filteredFirst === 0 || filteredLast === filtered.length - 1)
        ) {
          handleLasts({ date: timeSlot, isRemoval: 1 });
          setApplications(applications);
        }
        return applications;
      default:
        return applications;
    }
  };

  const removeDay = (filteredSlots) => {
    setErrorMessage(null);
    filteredSlots.forEach((filteredSlot) => handleApplications(filteredSlot, true));
    if (applications.length > 0) {
      setFieldValue('applications', applications);
    } else {
      const clone = { ...values };
      delete clone.applications;
      setValues(clone);
    }
    fetchSlots({ ...searchAsObject, applications });
  };

  useEffect(() => {
    setErrorMessage(null);
    setShowSuccessAlert(false);
    setApplications([]);
    fetchSlots(searchAsObject);
  }, [queryParams]);

  useEffect(() => {
    setTimeout(() => setShowSuccessAlert(false), 5000);
  }, [showSuccessAlert]);

  useEffect(() => {
    const findIndex = initialDates.findIndex((item) => item === initialDate);
    if (event.visibleDate.value === '5' && initialDates.length > 0 && findIndex > -1) {
      if (findIndex === 0) {
        const modifiedToolbar = {
          ...headerToolbar,
          left: 'customNextButton',
        };
        setHeaderToolbar(modifiedToolbar);
      } else if (findIndex === initialDates.length - 1) {
        const modifiedToolbar = {
          ...headerToolbar,
          left: 'customPrevButton',
        };
        setHeaderToolbar(modifiedToolbar);
      } else {
        const modifiedToolbar = {
          ...headerToolbar,
          left: 'customPrevButton,customNextButton',
        };
        setHeaderToolbar(modifiedToolbar);
      }
    }
  }, [initialDates]);

  const handleSelect = (arg) => {
    setErrorMessage(null);
    const calendarApi = arg.view.calendar;
    const found = calendarApi.getEvents().find((e) => e.startStr === arg.event.startStr);

    if (!found) {
      calendarApi.unselect();
      return;
    }

    handleApplications(found);
    calendarApi.unselect();
    if (applications.length > 0) {
      setFieldValue('applications', applications);
    } else {
      const clone = { ...values };
      delete clone.applications;
      setValues(clone);
    }
    fetchSlots({ ...searchAsObject, applications });
  };

  const handleClickCancel = () => {
    setErrorMessage(null);
    setApplications([]);
    const clone = { ...values };
    delete clone.applications;
    setValues(clone);
    fetchSlots(searchAsObject);
  };

  const handleClickFinalize = (async () => {
    setErrorMessage(null);
    setIsLoading(true);

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/application/finalize`, values);
      setShowSuccessAlert(true);
      setApplications([]);
      const clone = { ...values };
      delete clone.applications;
      setValues(clone);
      fetchSlots(searchAsObject);
      dispatch(getProfile());
      setIsLoading(false);
    } catch (err) {
      setErrorMessage(getErrorMessage(err));
      setApplications([]);
      const clone = { ...values };
      delete clone.applications;
      setValues(clone);
      fetchSlots(searchAsObject);
      setIsLoading(false);
    }
  });

  const handlePrevClick = () => {
    const calendarApi = calendarRef.current.getApi();
    const findIndex = initialDates.findIndex((item) => item === initialDate);

    if (findIndex > -1) {
      setInitialDate(initialDates[findIndex - 1]);
      calendarApi.gotoDate(initialDates[findIndex - 1]);

      if (findIndex - 1 === 0) {
        const modifiedToolbar = {
          ...headerToolbar,
          left: 'customNextButton',
        };
        setHeaderToolbar(modifiedToolbar);
      } else {
        const modifiedToolbar = {
          ...headerToolbar,
          left: 'customPrevButton,customNextButton',
        };
        setHeaderToolbar(modifiedToolbar);
      }
    }
  };

  const handleNextClick = () => {
    const calendarApi = calendarRef.current.getApi();
    const findIndex = initialDates.findIndex((item) => item === initialDate);

    if (findIndex > -1) {
      setInitialDate(initialDates[findIndex + 1]);
      calendarApi.gotoDate(initialDates[findIndex + 1]);

      if (findIndex + 1 === initialDates.length - 1) {
        const modifiedToolbar = {
          ...headerToolbar,
          left: 'customPrevButton',
        };
        setHeaderToolbar(modifiedToolbar);
      } else {
        const modifiedToolbar = {
          ...headerToolbar,
          left: 'customPrevButton,customNextButton',
        };
        setHeaderToolbar(modifiedToolbar);
      }
    }
  };

  const getContent = () => {
    if (ref.current !== true) {
      return (
        <div className="d-flex justify-content-center my-5">
          <CircularProgress />
        </div>
      );
    }

    return (
      <div>
        <div className="row">
          <div className="page-title col-12"><span>{t('pages.events.apply.title')}</span></div>
        </div>
        <AlertDialog
          isOpen={isModalOpen}
          onConfirm={onConfirm}
          contentText={t('alert.107Content')}
          confirmText={t('common.button.agree')}
        />
        <CalendarLegend slotStatuses={[1, 5, 2, 6, 3, 4, 8, 9]} />
        <FullCalendar
          ref={calendarRef}
          locales={[huLocale, enLocale]}
          locale={lang}
          plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
          customButtons={{
            customPrevButton: {
              icon: 'chevron-left',
              click: handlePrevClick,
            },
            customNextButton: {
              icon: 'chevron-right',
              click: handleNextClick,
            },
          }}
          headerToolbar={headerToolbar}
          contentHeight="auto"
          initialDate={initialDate}
          eventClick={(arg) => handleSelect(arg)}
          initialView="timeGridWeek"
          allDaySlot={false}
          dayHeaderContent={(arg) => {
            let arrayOfDomNodes = [];
            const filtered = getFilteredSlots(arg);
            const button = document.createElement('button');
            const span = document.createElement('span');
            span.innerHTML = arg.text;
            span.className = 'day-text';
            button.innerHTML = t('pages.events.button.dayRemove');
            button.className = 'fc-button fc-button-primary day-remove';
            button.type = 'button';
            button.addEventListener('click', () => {
              removeDay(filtered);
            });

            if (filtered.length > 0) {
              arrayOfDomNodes = [span, button];
            } else {
              arrayOfDomNodes = [span];
            }

            return { domNodes: arrayOfDomNodes };
          }}
          {...slotPropsObject}
          slotLabelContent={(arg) => {
            const span = document.createElement('span');
            span.innerHTML = arg.text;
            const arrayOfDomNodes = [span];

            return { domNodes: arrayOfDomNodes };
          }}
          events={slots}
          eventClassNames={(arg) => getColorClass(
            arg.event.extendedProps.status,
            arg.event.extendedProps.isTemporary,
            arg.event.extendedProps.isRemoval,
          )}
          eventContent={(arg) => {
            const span = document.createElement('span');
            const from = format(new Date(arg.event.startStr), 'HH:mm');
            const to = format(new Date(arg.event.endStr), 'HH:mm');
            span.innerHTML = `${from}-${to}`;
            const arrayOfDomNodes = [span];

            return { domNodes: arrayOfDomNodes };
          }}
          lazyFetching={false}
        />
        {showSuccessAlert && (
        <div className="d-flex justify-content-center row mt-2">
          <div className="mt-1">
            <Alert severity="success" className="col">
              {t('pages.events.apply.finalizeSuccess')}
            </Alert>
          </div>
        </div>
        )}
        {errorMessage && (
        <div className="d-flex justify-content-center row mt-2">
          <div className="mt-1">
            <Alert
              severity="error"
              className="col"
              action={(
                <IconButton
                  aria-label="close"
                  color="error"
                  size="small"
                  onClick={() => {
                    setErrorMessage(null);
                  }}
                >
                  <Close color="error" fontSize="inherit" />
                </IconButton>
              )}
            >
              {errorMessage}
            </Alert>
          </div>
        </div>
        )}
        <div className="d-flex justify-content-center row mt-2">
          <div className="col-auto col-sm-auto col-md-auto">
            <Button
              variant="contained"
              type="button"
              color="secondary"
              onClick={handleClickCancel}
              disabled={!(applications.length > 0)}
            >
              {t('common.button.cancel')}
            </Button>
          </div>
          <div className="col-auto col-sm-auto col-md-auto">
            <LoadingButton
              variant="contained"
              title={t('pages.events.button.applyFinalize')}
              type="button"
              onClick={handleClickFinalize}
              loading={isLoading}
              disabled={!(applications.length > 0)}
            />
          </div>
        </div>
      </div>
    );
  };

  return getContent();
}

CalendarPart.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number,
    visibilityStartFormatted: PropTypes.string,
    interval: PropTypes.shape({
      value: PropTypes.string,
    }),
  }).isRequired,
  childId: PropTypes.number,
};

CalendarPart.defaultProps = {
  childId: null,
};

export default CalendarPart;

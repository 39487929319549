import React from 'react';
import ProfileMain from '../../components/User/Profile/ProfileMain';
import AlertBar from '../../components/AlertBar';
import CheckPolicies from '../../components/CheckPolicies';

export default function Profile() {
  return (
    <>
      <CheckPolicies />
      <div className="container container__wrap profile col-12 col-sm-12 col-md-10 col-lg-10 col-xl-8 col-xxl-8">
        <AlertBar />
        <div className="row d-flex justify-content-center">
          <ProfileMain />
        </div>
      </div>
    </>
  );
}

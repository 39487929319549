import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useSearchParams, useNavigate } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
// import LoadingButton from '../LoadingButton';
import AutocompleteSearchInput from './AutocompleteSearchInput';

function Filter({ handleSearchButtonClick }) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  // const { t } = useTranslation();
  const [value, setValue] = useState(searchParams.get('text')?.length > 0 ? searchParams.get('text') : '');
  const [searchResults, setSearchResults] = useState([]);
  const [showOptions, setShowOptions] = useState(true);

  const handleChange = (event) => {
    setValue(event.target.value);
    setShowOptions(true);

    const path = `${process.env.REACT_APP_API_URL}/api/event/autocomplete?text=${event.target.value}`;
    axios.get(path).then((res) => setSearchResults(res.data));
  };

  const handleClickAway = () => {
    setShowOptions(false);
  };

  const handleItemClick = (event) => {
    setValue('');
    setShowOptions(false);
    setSearchResults([]);
    const itemId = event.target.getAttribute('value');
    const path = `/events/${itemId}`;
    navigate(path);
  };

  const handleOnKeyDown = (event) => {
    if (event.key === 'Enter' && handleSearchButtonClick) {
      handleSearchButtonClick(value);
    }
  };

  return (
    <div className="d-flex justify-content-center row search_panel">
      <div className="col-12 col-sm-auto col-md-auto mb-4">
        <AutocompleteSearchInput
          value={value}
          handleChange={handleChange}
          handleOnFocus={() => setShowOptions(true)}
          handleOnKeyDown={handleOnKeyDown}
          searchResults={searchResults}
          handleItemClick={handleItemClick}
          showOptions={showOptions}
          handleClickAway={handleClickAway}
        />
      </div>
      {/* <div className="col-12 col-sm-auto col-md-auto mb-4">
        <LoadingButton
          variant="contained"
          title={t('common.button.search')}
          type="button"
          onClick={() => handleSearchButtonClick(value)}
        />
      </div> */}
    </div>
  );
}

Filter.propTypes = {
  handleSearchButtonClick: PropTypes.func.isRequired,
};

export default Filter;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import SelectField from '../SelectField';
import CheckboxField from '../CheckboxField';

function ApplyPart({ selectOptions, isFound }) {
  const { t } = useTranslation();
  const { values } = useFormikContext();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (values.childId && values.childId !== '') {
      location.search = `?childId=${values.childId}`;
      navigate(location.pathname + location.search);
    }
  }, [values]);

  const renderElement = (fieldName) => {
    switch (fieldName) {
      case 'speakHungarian':
        return (
          <CheckboxField
            name={fieldName}
            label={t('pages.events.apply.speakHungarian')}
            disabled={isFound}
          />
        );
      case 'childId':
        return (
          <SelectField
            name={fieldName}
            label={t('pages.events.apply.childrenSelector')}
            options={selectOptions}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="row mb-4">
      {Object.keys(values).map((fieldName) => {
        let smNum;
        let mdNum;
        let lgNum;
        switch (fieldName) {
          case 'speakHungarian':
          case 'childId':
            smNum = 6;
            mdNum = 6;
            lgNum = 3;
            break;
          default:
            smNum = 12;
            mdNum = 12;
            lgNum = 12;
        }

        return (
          <div className={`col-12 col-sm-${smNum} col-md-${mdNum} col-lg-${lgNum} mb-2`} key={fieldName}>
            {renderElement(fieldName)}
          </div>
        );
      })}
    </div>
  );
}

ApplyPart.propTypes = {
  selectOptions: PropTypes.arrayOf(PropTypes.shape({})),
  isFound: PropTypes.bool.isRequired,
};

ApplyPart.defaultProps = {
  selectOptions: [],
};

export default ApplyPart;

import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import {
  FormControlLabel, Checkbox, FormHelperText,
} from '@mui/material';

function CheckboxField({
  label, required, disabled, marginTop, ...props
}) {
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;

  const handleChange = (event) => {
    setValue(event.target.checked);
  };

  return (
    <>
      <FormControlLabel
        {...field}
        className="mx-2"
        control={(
          <Checkbox
            checked={field.value}
            onChange={handleChange}
          />
        )}
        label={label}
        required={required}
        disabled={disabled}
        sx={{
          marginTop,
        }}
      />
      <FormHelperText error={meta.touched && Boolean(meta.error)}>
        {meta.touched && meta.error}
      </FormHelperText>
    </>
  );
}

CheckboxField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  marginTop: PropTypes.string,
};

CheckboxField.defaultProps = {
  label: '',
  required: false,
  disabled: false,
  marginTop: '32px',
};

export default CheckboxField;

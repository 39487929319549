import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'reduxjs-toolkit-persist';
import storage from 'reduxjs-toolkit-persist/lib/storage';
import authReducer from './components/User/services/authSlice';
import i18nReducer from './components/Lang/services/i18nSlice';
import dialogReducer from './components/User/services/dialogSlice';
import eventReducer from './components/Event/services/eventSlice';

const reducers = combineReducers({
  auth: authReducer,
  dialog: dialogReducer,
  i18n: i18nReducer,
  event: eventReducer,
});

const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = (state, action) => {
  if (action.type === 'auth/logOut') {
    storage.removeItem('root');
    return reducers(undefined, action);
  }
  return reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export function makeStore() {
  return configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  });
}

const store = makeStore();

export default store;

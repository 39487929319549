import React from 'react';
import LoginForm from '../../components/User/Login/LoginForm';

export default function Login() {
  return (
    <div className="container container__wrap login col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
      <div className="row d-flex justify-content-center">
        <LoginForm />
      </div>
    </div>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, useFormikContext } from 'formik';
import { Button, FormHelperText } from '@mui/material';
import LoadingButton from '../../../LoadingButton';
import InputField from '../../InputField';
import getPattern from '../../../../helpers/getPattern';

function ConnectionForm({
  type, handleClose, errorMessage, isLoading,
}) {
  const { t } = useTranslation();
  const { values } = useFormikContext();

  const renderElement = (field) => {
    switch (field) {
      default:
        return (
          <InputField
            name={field}
            label={t(`user.${field}`)}
            required
            hidden={field === 'id'}
            pattern={getPattern(field)}
          />
        );
    }
  };

  return (
    <div className="col">
      <Form noValidate>
        <div className="row">
          {Object.keys(values).map((fieldName) => {
            let smNum;
            let mdNum;

            switch (fieldName) {
              case 'firstName':
              case 'lastName':
              case 'phoneNumber':
              case 'relationshipType':
                smNum = 6;
                mdNum = 6;
                break;
              default:
                smNum = 12;
                mdNum = 12;
            }

            return (
              <div className={`col-12 col-sm-${smNum} col-md-${mdNum} mb-4`} key={fieldName}>
                {renderElement(fieldName)}
              </div>
            );
          })}
        </div>
        {errorMessage && (
        <div className="d-flex justify-content-center row">
          <div className="col-12 col-sm-auto col-md-auto mt-2">
            <FormHelperText error>
              {errorMessage}
            </FormHelperText>
          </div>
        </div>
        )}
        <div className="d-flex justify-content-center row">
          <div className="col-12 col-sm-6 col-md-4 mt-4">
            <Button
              fullWidth
              variant="contained"
              type="button"
              color="secondary"
              onClick={handleClose}
            >
              {t('common.button.cancel')}
            </Button>
          </div>
          <div className="col-12 col-sm-6 col-md-4 mt-4 mb-4">
            <LoadingButton
              fullWidth
              variant="contained"
              title={type === 'create' ? t('common.button.save') : t('common.button.edit')}
              type="submit"
              loading={isLoading}
            />
          </div>
        </div>
      </Form>
    </div>
  );
}

ConnectionForm.propTypes = {
  type: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  isLoading: PropTypes.bool,
};

ConnectionForm.defaultProps = {
  type: 'create',
  errorMessage: null,
  isLoading: false,
};

export default ConnectionForm;

import axios from 'axios';

const url = `${process.env.REACT_APP_API_URL}/api`;

export const postSignUpData = async (userData) => {
  const response = await axios.post(`${url}/registration`, userData);
  return response.data;
};

export const postLogInData = async (userData) => {
  const response = await axios.post(`${url}/login`, userData);
  return response.data;
};

export const postTokenData = async (tokenData) => {
  const response = await axios.post(`${url}/get-user-by-token`, tokenData);
  return response.data;
};

export const getUserData = async (token) => {
  let config = null;
  if (token !== undefined) {
    config = { headers: { 'X-AUTH-TOKEN': token } };
  }
  const response = await axios.get(
    `${url}/v1/user/user-data`,
    config,
  );
  return response.data;
};

export const putUpdateUser = async (updateData) => {
  const response = await axios.put(
    `${url}/v1/user/update`,
    updateData,
  );
  return response.data;
};

export const putUpdatePassword = async (updateData) => {
  const response = await axios.put(
    `${url}/v1/user/update-password`,
    updateData,
  );
  return response.data;
};

export const deleteUser = async () => {
  const response = await axios.delete(
    `${url}/v1/user/delete`,
  );
  return response.data;
};

export const postAddChild = async (childData) => {
  const response = await axios.post(
    `${url}/v1/user/add-child`,
    childData,
  );
  return response.data;
};

export const putUpdateChild = async (id, updateData) => {
  const response = await axios.put(
    `${url}/v1/child/update/${id}`,
    updateData,
  );
  return response.data;
};

export const deleteDeleteChild = async (id) => {
  const response = await axios.delete(
    `${url}/v1/child/delete/${id}`,
  );
  return response.data;
};

export const postChildAddConnection = async (id, addData) => {
  const response = await axios.post(
    `${url}/v1/child/add-connection/${id}`,
    addData,
  );
  return response.data;
};

export const putUpdateConnection = async (id, updateData) => {
  const response = await axios.put(
    `${url}/v1/connection/update/${id}`,
    updateData,
  );
  return response.data;
};

export const deleteDeleteConnection = async (id) => {
  const response = await axios.delete(
    `${url}/v1/connection/delete/${id}`,
  );
  return response.data;
};

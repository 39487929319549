import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import huLocale from '@fullcalendar/core/locales/hu';
import enLocale from '@fullcalendar/core/locales/en-gb';
import CalendarLegend from '../../CalendarLegend';
import { setSelected } from '../services/eventSlice';

function MyEventCalendar({
  lang, slots, handleItem, view, setView,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <CalendarLegend slotStatuses={[3, 4]} />
      <FullCalendar
        locales={[huLocale, enLocale]}
        locale={lang}
        plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,listWeek',
        }}
        contentHeight="auto"
        initialView={view}
        viewDidMount={(arg) => {
          setView(arg.view.type);
        }}
        allDaySlot={false}
        eventTimeFormat={{
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        }}
        events={slots}
        eventClassNames={(arg) => {
          if (arg.event.extendedProps.isWaitingList) {
            return ['waiting-list-event'];
          }
          return ['own-event'];
        }}
        // eslint-disable-next-line consistent-return
        eventContent={(arg) => {
          if (arg.view.type === 'listWeek') {
            const today = format(new Date(), 'yyyy-MM-dd HH:mm');
            const timeSlot = format(new Date(arg.event.start), 'yyyy-MM-dd HH:mm');
            const a = document.createElement('a');
            const button = document.createElement('button');
            let arrayOfDomNodes = [];
            a.href = arg.event.url;
            a.innerHTML = arg.event.title;
            button.innerHTML = t('pages.events.button.applyRemove');
            button.className = 'fc-button fc-button-primary apply-remove';
            button.type = 'button';
            button.addEventListener('click', (e) => {
              e.preventDefault();
              const postData = {
                eventId: arg.event.extendedProps.eventId,
                applyDate: timeSlot,
              };
              if (arg.event.extendedProps.child !== undefined) {
                postData.childId = arg.event.extendedProps.child.id;
              }
              handleItem(postData);
            });

            if (timeSlot <= today || arg.event.extendedProps.simplifiedApplication) {
              arrayOfDomNodes = [a];
            } else {
              arrayOfDomNodes = [a, button];
            }

            return { domNodes: arrayOfDomNodes };
          }
        }}
        eventDidMount={(arg) => {
          if (arg.view.type === 'dayGridMonth') {
            const timeStart = format(new Date(arg.event.start), 'HH:mm');
            const timeEnd = arg.event.end !== null ? format(new Date(arg.event.end), 'HH:mm') : null;
            const copyEl = arg.el;
            if (timeEnd !== null) {
              const time = `${timeStart}-${timeEnd}`;
              copyEl.childNodes[0].childNodes[0].childNodes[0].childNodes[0].data = time;
            }

            return copyEl;
          }

          return arg.el;
        }}
        eventClick={(arg) => {
          const applyDay = format(new Date(arg.event.start), 'yyyy-MM-dd');
          dispatch(setSelected({ id: arg.event.extendedProps.eventId, day: applyDay }));
        }}
        lazyFetching={false}
        dayMaxEventRows={2}
      />
    </>
  );
}

MyEventCalendar.propTypes = {
  lang: PropTypes.string.isRequired,
  slots: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleItem: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
  setView: PropTypes.func.isRequired,
};

export default MyEventCalendar;

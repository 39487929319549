import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import {
  FormHelperText, Alert, IconButton, Collapse,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import LoadingButton from '../../LoadingButton';
import ChildPart from '../SignUp/ChildPart';
import createChildValidationSchema from '../../../helpers/user/createChildValidationSchema';
import { addChild, reset } from '../services/authSlice';
import PromptIfDirty from '../../PromptIfDirty';

function AddChildForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const validationSchema = createChildValidationSchema();
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const { loading, message, account } = useSelector((state) => state.auth);
  const { lang } = useSelector((state) => state.i18n);

  const initConnection = {
    lastName: '',
    firstName: '',
    phoneNumber: '',
    relationshipType: '',
  };

  const initChild = {
    lastName: '',
    firstName: '',
    birthDate: '',
    gender: '',
    howToCall: '',
    specialRequirements: '',
    languages: [lang],
    connections: [initConnection],
  };

  const initialValues = {
    parent: account.id,
    children: [initChild],
  };

  useEffect(() => {
    if (!isSaving) {
      return;
    }
    switch (loading) {
      case 'failed':
        setShowSuccessAlert(false);
        setErrorMessage(message);
        setIsLoading(false);
        break;
      case 'pending':
        setShowSuccessAlert(false);
        setIsLoading(true);
        break;
      case 'succeeded':
        setShowSuccessAlert(true);
        setErrorMessage(null);
        setIsSaving(false);
        setIsLoading(false);
        break;
      default:
    }
    dispatch(reset());
  }, [loading, message]);

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          setIsSaving(true);
          dispatch(addChild(values));
          resetForm();
        }}
      >
        <div className="col">
          <Form noValidate>
            <PromptIfDirty init={initialValues} />
            <ChildPart init={initChild} initConnection={initConnection} oneDelete={false} />
            <Collapse in={showSuccessAlert}>
              <div className="p-0 mt-1">
                <Alert
                  severity="success"
                  action={(
                    <IconButton
                      aria-label="close"
                      color="success"
                      size="small"
                      onClick={() => {
                        setShowSuccessAlert(false);
                      }}
                    >
                      <Close color="success" fontSize="inherit" />
                    </IconButton>
                  )}
                >
                  {t('common.saveSuccess')}
                </Alert>
              </div>
            </Collapse>
            {errorMessage && (
              <div className="d-flex justify-content-center row">
                <div className="col-12 col-sm-auto col-md-auto mt-2">
                  <FormHelperText error>
                    {errorMessage}
                  </FormHelperText>
                </div>
              </div>
            )}
            <div className="d-flex justify-content-center row">
              <div className="col-12 col-sm-6 col-md-4 mt-4 mb-4">
                <LoadingButton
                  fullWidth
                  variant="contained"
                  title={t('common.button.save')}
                  type="submit"
                  loading={isLoading}
                />
              </div>
            </div>
          </Form>
        </div>
      </Formik>
    </div>
  );
}

export default AddChildForm;

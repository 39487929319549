import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useSearchParams, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { Formik, Form } from 'formik';
import {
  Button, Alert, IconButton, Collapse,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { format } from 'date-fns';
import ApplyPart from './ApplyPart';
import { getChildrenOptions, getInitialValues, getValidationSchema } from '../../helpers/applyHelper';
import CalendarPart from './CalendarPart';
import AvailablePart from './AvailablePart';
import AlertBar from '../AlertBar';
import getErrorMessage from '../../helpers/getErrorMessage';
import { getProfile } from '../User/services/authSlice';
import LoadingButton from '../LoadingButton';
import DynamicDialog from '../User/Profile/DynamicDialog';
import DeleteConfirm from '../User/Profile/Child/DeleteConfirm';

function ApplyForm({ event, lang }) {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);
  const [dataForDialog, setDataForDialog] = useState({});
  const { account, isAdminLogin, isAuthenticated } = useSelector((state) => state.auth);
  const { selected } = useSelector((state) => state.event);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [onlyWaitingList, setOnlyWaitingList] = useState(false);
  const [noApply, setNoApply] = useState(false);
  const today = format(new Date(), 'yyyy-MM-dd');
  const visibilityStart = format(new Date(event.visibilityStart), 'yyyy-MM-dd');
  const visibilityEnd = format(new Date(event.visibilityEnd), 'yyyy-MM-dd');
  const isDeleteable = isAdminLogin ? true : (visibilityStart <= today && today <= visibilityEnd);
  const searchParamChildId = searchParams.get('childId')?.length > 0
    ? parseInt(searchParams.get('childId'), 10) : null;
  const [found, setFound] = useState(undefined);

  const getFound = () => {
    if (account === null) return undefined;
    if (selected === null && account === null) return undefined;

    if (searchParamChildId === null) {
      return account.applies.find((apply) => (apply.event.id === event.id
        && apply.applyDate.indexOf(selected?.day) === 0)
        || (apply.event.id === event.id && event.simplifiedApplication));
    }
    return account.applies.find((apply) => (apply.event.id === event.id
        && apply.applyDate.indexOf(selected?.day) === 0 && apply.child.id === searchParamChildId)
        || (apply.event.id === event.id
          && apply.child.id === searchParamChildId && event.simplifiedApplication
        ));
  };

  const fetchSlots = (async (postData) => {
    setErrorMessage(null);
    await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/application/slots`, postData)
      .then((res) => {
        const newArray = Object.entries(res.data);
        if (newArray.length > 0 && newArray[0][1] === 2) {
          setOnlyWaitingList(true);
        }
        if (newArray.length > 0 && newArray[0][1] === 5) {
          setNoApply(true);
        }
      }).catch((err) => {
        setErrorMessage(getErrorMessage(err));
      });
  });

  useEffect(() => {
    if (event.withoutChildrenApplication) {
      setFound(getFound);
    }
    if (!event.simplifiedApplication && searchParamChildId !== null) {
      setFound(getFound);
    }
  }, []);

  useEffect(() => {
    setFound(getFound);
  }, [account]);

  useEffect(() => {
    if (found) {
      setShowCalendar(true);
    } else {
      setShowCalendar(false);
    }
  }, [found]);

  useEffect(() => {
    if (noApply && found === undefined) {
      setErrorMessage(t('pages.events.apply.noApply'));
    }
  }, [noApply, t]);

  useEffect(() => {
    setShowSuccessAlert(false);
    setErrorMessage(null);
    setNoApply(false);
    setOnlyWaitingList(false);
    if (!event.simplifiedApplication && searchParamChildId !== null) {
      setFound(getFound);
    }
    if (event.simplifiedApplication && !event.withoutChildrenApplication
      && searchParamChildId === null) {
      setFound(undefined);
    }
    if (event.simplifiedApplication && searchParamChildId !== null) {
      setFound(getFound);
    }
    if (account !== null && event.simplifiedApplication) {
      fetchSlots({ eventId: event.id });
    }
  }, [searchParamChildId]);

  const handleSubmit = async (formData) => {
    if (event.simplifiedApplication) {
      setErrorMessage(null);
      setIsLoading(true);
      setShowSuccessAlert(false);

      try {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/application`, formData);
        dispatch(getProfile());
        setIsLoading(false);
        setShowSuccessAlert(true);
      } catch (err) {
        setErrorMessage(getErrorMessage(err));
        setIsLoading(false);
        setShowSuccessAlert(false);
      }
    } else {
      setShowCalendar(true);
    }
  };

  const handleRemove = async (formData) => {
    setShowSuccessAlert(false);
    setErrorMessage(null);
    setIsLoading(true);

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/application/remove`, formData);
      dispatch(getProfile());
      setIsLoading(false);
      setOpenDialog(false);
      setNoApply(false);
      setOnlyWaitingList(false);
      if (event.simplifiedApplication) {
        fetchSlots({ eventId: event.id });
      }
    } catch (err) {
      setErrorMessage(getErrorMessage(err));
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
    setDataForDialog({});
  };

  const handleClickOpen = (data) => {
    setErrorMessage(null);
    setDataForDialog(data);
    setOpenDialog(true);
  };

  const handleItem = (formData) => {
    handleClickOpen(formData);
  };

  const getContent = () => {
    if (!isAuthenticated) {
      return (
        <Alert severity="info">
          <Trans
            i18nKey="pages.events.apply.noLoggedIn"
            components={[
              <Link to="/login" />,
            ]}
          />
        </Alert>
      );
    }

    if (isAuthenticated && !account.isActive) {
      return <AlertBar />;
    }

    return (
      <div>
        <DynamicDialog
          open={openDialog}
          handleClose={handleClose}
          type="delete"
        >
          <DeleteConfirm
            title={t('pages.events.apply.deletePrompt')}
            handleClose={handleClose}
            handleClickConfirm={() => {
              handleRemove(dataForDialog);
            }}
            error={errorMessage}
            isLoading={isLoading}
          />
        </DynamicDialog>
        <Formik
          enableReinitialize
          initialValues={getInitialValues(account, isAdminLogin, event, found, searchParams)}
          validationSchema={getValidationSchema(event)}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <div className="col">
              <Form noValidate>
                <ApplyPart
                  selectOptions={getChildrenOptions(account, event, lang)}
                  isFound={found !== undefined}
                />
                {event.simplifiedApplication && (<AvailablePart event={event} />)}
                {errorMessage && (
                <div className="py-2">
                  <Alert
                    severity="error"
                    className="col"
                    action={(
                      <IconButton
                        aria-label="close"
                        color="error"
                        size="small"
                        onClick={() => {
                          setErrorMessage(null);
                        }}
                      >
                        <Close color="error" fontSize="inherit" />
                      </IconButton>
                    )}
                  >
                    {errorMessage}
                  </Alert>
                </div>
                )}
                {!showCalendar && (
                <div className="d-flex justify-content-center row">
                  <div className="col-auto col-sm-auto col-md-auto">
                    <LoadingButton
                      variant="contained"
                      title={onlyWaitingList ? t('pages.events.button.applyForWaitingList') : t('pages.events.button.apply')}
                      type="submit"
                      loading={isLoading}
                      disabled={noApply}
                    />
                  </div>
                </div>
                )}
                {event.simplifiedApplication && showSuccessAlert && (
                  <Collapse in={showSuccessAlert}>
                    <div className="p-0 mt-1 mb-1">
                      <Alert
                        severity="success"
                        action={(
                          <IconButton
                            aria-label="close"
                            color="success"
                            size="small"
                            onClick={() => {
                              setShowSuccessAlert(false);
                            }}
                          >
                            <Close color="success" fontSize="inherit" />
                          </IconButton>
                      )}
                      >
                        {onlyWaitingList ? t('pages.events.apply.successForWaitingList') : t('pages.events.apply.success')}
                      </Alert>
                    </div>
                  </Collapse>
                )}
                {showCalendar && event.simplifiedApplication && found && (
                <div className="row mb-2">
                  <div className="event-status col-12">
                    <span>{`${t('pages.events.apply.status.title')}: ${found.isWaitingList ? t('pages.events.apply.status.4') : t('pages.events.apply.status.3')}`}</span>
                  </div>
                </div>
                )}
                {event.simplifiedApplication && found && isDeleteable && (
                <div className="row mb-4">
                  <div className="col-12">
                    <Button
                      variant="contained"
                      type="button"
                      onClick={() => handleItem(formik.values)}
                    >
                      {isAdminLogin ? t('pages.events.button.applyAdminRemove') : t('pages.events.button.applyRemove')}
                    </Button>
                  </div>
                </div>
                )}
              </Form>
              {showCalendar && !event.simplifiedApplication && (
              <CalendarPart event={event} childId={searchParamChildId} />
              )}
            </div>
          )}
        </Formik>
      </div>
    );
  };

  return getContent();
}

ApplyForm.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  lang: PropTypes.string.isRequired,
};

export default ApplyForm;

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import {
  FormHelperText, Alert, IconButton, Collapse,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import LoadingButton from '../../LoadingButton';
import PasswordField from '../PasswordField';
import { updatePassword, reset } from '../services/authSlice';
import addValidation from '../../../helpers/user/addValidation';
import PromptIfDirty from '../../PromptIfDirty';

const initialValues = {
  oldPassword: '',
  password: '',
  passwordAgain: '',
};

function ChangePasswordForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { loading, message } = useSelector((state) => state.auth);

  const validationSchema = yup.object({
    oldPassword: yup.string().required(t('error.requiredField')),
    password: addValidation('password'),
    passwordAgain: addValidation('passwordAgain'),
  });

  useEffect(() => {
    if (!isSaving) {
      return;
    }
    switch (loading) {
      case 'failed':
        setShowSuccessAlert(false);
        setErrorMessage(message);
        setIsLoading(false);
        break;
      case 'pending':
        setShowSuccessAlert(false);
        setIsLoading(true);
        break;
      case 'succeeded':
        setShowSuccessAlert(true);
        setErrorMessage(null);
        setIsSaving(false);
        setIsLoading(false);
        break;
      default:
    }
    dispatch(reset());
  }, [loading, message]);

  const renderElement = (fieldName) => {
    switch (fieldName) {
      default:
        return (
          <PasswordField
            name={fieldName}
            label={t(`user.${fieldName}`)}
            required
          />
        );
    }
  };

  return (
    <div className="row d-flex justify-content-center">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          setIsSaving(true);
          dispatch(updatePassword(values));
          resetForm();
        }}
      >
        {(formik) => (
          <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
            <Form noValidate>
              <PromptIfDirty init={initialValues} />
              <div className="row">
                {Object.keys(formik.values).map((fieldName) => (
                  <div className="col-12 col-sm-12 col-md-12 mt-2 mb-4" key={fieldName}>
                    {renderElement(fieldName)}
                  </div>
                ))}
              </div>
              {errorMessage && (
                <div className="d-flex justify-content-center row">
                  <div className="col-12 col-sm-auto col-md-auto mt-2">
                    <FormHelperText error>
                      {errorMessage}
                    </FormHelperText>
                  </div>
                </div>
              )}
              <Collapse in={showSuccessAlert}>
                <div className="p-0 mt-1">
                  <Alert
                    severity="success"
                    action={(
                      <IconButton
                        aria-label="close"
                        color="success"
                        size="small"
                        onClick={() => {
                          setShowSuccessAlert(false);
                        }}
                      >
                        <Close color="success" fontSize="inherit" />
                      </IconButton>
                  )}
                  >
                    {t('common.editSuccess')}
                  </Alert>
                </div>
              </Collapse>
              <div className="d-flex justify-content-center row">
                <div className="col-12 col-sm-6 col-md-6 mt-4 mb-4">
                  <LoadingButton
                    fullWidth
                    variant="contained"
                    title={t('common.button.edit')}
                    type="submit"
                    loading={isLoading}
                  />
                </div>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default ChangePasswordForm;

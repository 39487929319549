import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { FormHelperText, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import LoadingButton from '../../LoadingButton';
import InputField from '../InputField';
import PasswordField from '../PasswordField';
import { logIn, reset } from '../services/authSlice';
import createLogInValidationSchema from '../../../helpers/user/createLogInValidationSchema';

const initialValues = {
  email: '',
  password: '',
};

function LoginForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const validationSchema = createLogInValidationSchema();
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { loading, message, isAuthenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!isSaving) {
      return;
    }
    switch (loading) {
      case 'failed':
        setErrorMessage(message);
        setIsLoading(false);
        break;
      case 'pending':
        setIsLoading(true);
        break;
      case 'succeeded':
        setIsSaving(false);
        setIsLoading(false);
        navigate('/');
        break;
      default:
    }
    dispatch(reset());
  }, [loading, message]);

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated]);

  const renderElement = (fieldName) => {
    switch (fieldName) {
      case 'password':
        return (
          <PasswordField
            name={fieldName}
            label={t(`user.${fieldName}`)}
          />
        );
      default:
        return (
          <InputField
            name={fieldName}
            label={t(`user.${fieldName}`)}
          />
        );
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          setIsSaving(true);
          dispatch(logIn(values));
        }}
      >
        {(formik) => (
          <div className="col">
            <div className="row">
              <div className="col">
                <div className="page-title text-center mb-3">
                  {t('pages.login.title')}
                </div>
              </div>
            </div>
            <Form noValidate>
              <div className="row">
                {Object.keys(formik.values).map((fieldName) => (
                  <div className={`col-12 col-sm-12 col-md-12 mt-2 ${fieldName === 'email' ? 'mb-4' : ''}`} key={fieldName}>
                    {renderElement(fieldName)}
                  </div>
                ))}
              </div>
              <div className="d-flex justify-content-end row">
                <div className="col-12 col-sm-auto col-md-auto">
                  <Link to="/forgot">{t('pages.login.forgotPassword')}</Link>
                </div>
              </div>
              {errorMessage && (
              <div className="d-flex justify-content-center row">
                <div className="col-12 col-sm-auto col-md-auto mt-2">
                  <FormHelperText error>
                    {errorMessage}
                  </FormHelperText>
                </div>
              </div>
              )}
              <div className="d-flex justify-content-center row">
                <div className="col-12 col-sm-6 col-md-6 mt-4">
                  <LoadingButton
                    fullWidth
                    variant="contained"
                    title={t('pages.login.title')}
                    type="submit"
                    loading={isLoading}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-center row">
                <div className="col-12 col-sm-6 col-md-6 mt-2">
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    href="/signup"
                  >
                    {t('pages.signUp.title')}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default LoginForm;

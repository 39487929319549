const getPattern = (field) => {
  switch (field) {
    case 'phoneNumber':
      return /^([+]{1}|\d+|[+]{1}\d+)$/;
    case 'houseNumber':
      return /^[1-9]\d*(?:[/]?(?:[a-zA-Z]?))?$/;
    case 'zipCode':
    case 'floor':
    case 'door':
      return /^\d+$/;
    case 'lastName':
    case 'firstName':
      return /^[A-Za-zÁÉŰÚŐÓÜÖÍáéűúőóüöí .-]*$/;
    default:
      return null;
  }
};

export default getPattern;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { FormHelperText } from '@mui/material';
import FormNavigation from './FormNavigation';

function MultiStepForm({
  children, initialValues, onSubmit, errorMessage, isLoading,
}) {
  const [stepNumber, setStepNumber] = useState(0);
  const steps = React.Children.toArray(children);
  const [snapshot, setSnapshot] = useState(initialValues);

  const step = steps[stepNumber];
  const totalSteps = steps.length;
  const isLastStep = stepNumber === totalSteps - 1;

  const next = (values) => {
    setSnapshot(values);
    setStepNumber(stepNumber + 1);
  };

  const previous = (values) => {
    setSnapshot(values);
    setStepNumber(stepNumber - 1);
  };

  // eslint-disable-next-line consistent-return
  const handleSubmit = async (values, bag) => {
    if (step.props.onSubmit) {
      await step.props.onSubmit(values);
    }

    if (isLastStep) {
      return onSubmit(values, bag);
    // eslint-disable-next-line no-else-return
    } else {
      bag.setTouched({});
      next(values);
    }
  };

  return (
    <div>
      <Formik
        initialValues={snapshot}
        validationSchema={step.props.validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <div className="col">
            <div className="row">
              <div className="col">
                <div className="page-title text-center mb-3">
                  {step.props.stepName}
                </div>
              </div>
            </div>
            <Form noValidate>
              {step}
              {errorMessage && (
              <div className="d-flex justify-content-center row">
                <div className="col-12 col-sm-auto col-md-auto mt-2">
                  <FormHelperText error>
                    {errorMessage}
                  </FormHelperText>
                </div>
              </div>
              )}
              <FormNavigation
                isLastStep={isLastStep}
                hasPrevious={stepNumber > 0}
                onBackClick={() => previous(formik.values)}
                isLoading={isLoading}
              />
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
}

MultiStepForm.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  onSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  isLoading: PropTypes.bool,
};

MultiStepForm.defaultProps = {
  errorMessage: null,
  isLoading: false,
};

export default MultiStepForm;

export const FormStep = ({ children }) => children;

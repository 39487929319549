import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { deleteUser } from './authService';
import getErrorMessage from '../../../helpers/getErrorMessage';

const initialState = {
  loading: 'idle',
  message: null,
};

export const deleteProfile = createAsyncThunk('dialog/deleteProfile', async (data, thunkAPI) => {
  try {
    const deletedProfile = await deleteUser();
    return {
      data: deletedProfile.data,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return thunkAPI.rejectWithValue(errorMessage);
  }
});

export const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    reset: (state) => {
      state.loading = 'idle';
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteProfile.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(deleteProfile.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.message = action.payload.data;
      })
      .addCase(deleteProfile.rejected, (state, action) => {
        state.loading = 'failed';
        state.message = action.payload;
      });
  },
});

export const { reset } = dialogSlice.actions;
export default dialogSlice.reducer;

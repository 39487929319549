import * as React from 'react';
import {
  Button, DialogActions, DialogTitle, Alert,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import LoadingButton from '../../../LoadingButton';

function DeleteConfirm({
  title, handleClose, handleClickConfirm, error, isLoading,
}) {
  const { t } = useTranslation();
  return (
    <>
      {error && (
        <Alert
          severity="error"
          sx={{
            color: '#F16123',
            backgroundColor: 'inherit',
          }}
        >
          {error}
        </Alert>
      )}
      <DialogTitle
        textAlign="center"
        component="div"
        sx={{ padding: '20px 40px' }}
      >
        <div className="d-flex justify-content-center row">
          <div className="col col-md-auto">
            <span>{title !== '' ? title : t('common.deletePrompt')}</span>
          </div>
        </div>
      </DialogTitle>
      <DialogActions
        className="mb-4"
        sx={{
          justifyContent: 'center',
          paddingLeft: '24px',
          paddingRight: '24px',
        }}
      >
        <div className="col">
          <div className="d-flex justify-content-center row">
            <div className="col-12 col-sm-6 col-md-4 mt-4">
              <Button
                fullWidth
                variant="contained"
                type="button"
                color="secondary"
                onClick={handleClose}
              >
                {t('common.button.cancel')}
              </Button>
            </div>
            <div className="col-12 col-sm-6 col-md-4 mt-4 mb-2">
              <LoadingButton
                fullWidth
                variant="contained"
                title={t('common.button.yes')}
                type="button"
                onClick={handleClickConfirm}
                loading={isLoading}
              />
            </div>
          </div>
        </div>
      </DialogActions>
    </>
  );
}

DeleteConfirm.propTypes = {
  title: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  handleClickConfirm: PropTypes.func.isRequired,
  error: PropTypes.string,
  isLoading: PropTypes.bool,
};

DeleteConfirm.defaultProps = {
  title: '',
  error: null,
  isLoading: false,
};

export default DeleteConfirm;

import React, { useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import {
  FormHelperText, Alert, IconButton, Collapse,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import LoadingButton from '../../LoadingButton';
import PasswordField from '../PasswordField';
import addValidation from '../../../helpers/user/addValidation';
import getErrorMessage from '../../../helpers/getErrorMessage';

const initialValues = {
  password: '',
  passwordAgain: '',
};

function ForgotPasswordForm() {
  const { token } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const validationSchema = yup.object({
    password: addValidation('password'),
    passwordAgain: addValidation('passwordAgain'),
  });

  const renderElement = (fieldName) => {
    switch (fieldName) {
      default:
        return (
          <PasswordField
            name={fieldName}
            label={t(`user.${fieldName}`)}
            required
          />
        );
    }
  };

  const handleSubmit = async (values) => {
    const body = { ...values, token };
    setErrorMessage(null);
    setSuccessMessage(null);
    setIsLoading(true);
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/save-new-password`, body);
      setSuccessMessage(res.data.data);
      setShowSuccessAlert(true);
      setIsLoading(false);
      navigate('/login');
    } catch (err) {
      setShowSuccessAlert(false);
      setErrorMessage(getErrorMessage(err));
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values);
          resetForm();
        }}
      >
        {(formik) => (
          <div className="col">
            <div className="row">
              <div className="col">
                <div className="page-title text-center mb-3">
                  {t('pages.forgot.newPassword')}
                </div>
              </div>
            </div>
            <Form noValidate>
              <div className="row">
                {Object.keys(formik.values).map((fieldName) => (
                  <div className="col-12 col-sm-12 col-md-12 mt-2 mb-4" key={fieldName}>
                    {renderElement(fieldName)}
                  </div>
                ))}
              </div>
              <Collapse in={showSuccessAlert}>
                <div className="p-0 mt-1">
                  <Alert
                    severity="success"
                    action={(
                      <IconButton
                        aria-label="close"
                        color="success"
                        size="small"
                        onClick={() => {
                          setShowSuccessAlert(false);
                        }}
                      >
                        <Close color="success" fontSize="inherit" />
                      </IconButton>
                  )}
                  >
                    {successMessage}
                  </Alert>
                </div>
              </Collapse>
              {errorMessage && (
              <div className="d-flex justify-content-center row">
                <div className="col-12 col-sm-auto col-md-auto mt-2">
                  <FormHelperText error>
                    {errorMessage}
                  </FormHelperText>
                </div>
              </div>
              )}
              <div className="d-flex justify-content-center row">
                <div className="col-12 col-sm-6 col-md-6 mt-4">
                  <LoadingButton
                    fullWidth
                    variant="contained"
                    title={t('common.button.save')}
                    type="submit"
                    loading={isLoading}
                  />
                </div>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default ForgotPasswordForm;

import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { InputLabel, Switch } from '@mui/material';

function SwitchField({
  label, required, disabled, hidden, ...props
}) {
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;

  const handleChange = (event) => {
    setValue(event.target.checked);
  };

  return (
    <>
      <InputLabel
        required={required}
        disabled={disabled}
        hidden={hidden}
        className={meta.touched && Boolean(meta.error) ? 'Mui-error' : ''}
      >
        {label}
      </InputLabel>
      <Switch
        {...field}
        checked={field.value}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled' }}
      />
    </>
  );
}

SwitchField.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
};

SwitchField.defaultProps = {
  label: '',
  required: false,
  disabled: false,
  hidden: false,
};

export default SwitchField;

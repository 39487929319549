import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { InputLabel, TextField, MenuItem } from '@mui/material';

function SelectField({
  label, options, required, disabled, ...props
}) {
  const [field, meta] = useField(props);

  return (
    <>
      <InputLabel
        required={required}
        disabled={disabled}
        className={meta.touched && Boolean(meta.error) ? 'Mui-error' : ''}
      >
        {label}
      </InputLabel>
      <TextField
        InputProps={{ disableUnderline: true }}
        fullWidth
        required={required}
        disabled={disabled}
        {...field}
        {...props}
        select
        variant="filled"
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
}

SelectField.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

SelectField.defaultProps = {
  label: '',
  required: false,
  disabled: false,
};

export default SelectField;

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, Tab, Button } from '@mui/material';
import UserForm from './UserForm';
import MyChildrenForm from './MyChildrenForm';
import AddChildForm from './AddChildForm';
import ChangePasswordForm from './ChangePasswordForm';
import { deleteProfile, reset } from '../services/dialogSlice';
import { logOut } from '../services/authSlice';
import ProfileDialog from './ProfileDialog';

function TabPanel({
  children, value, index, ...other
}) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          { children }
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.element.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function ButtonInTabs({ onClick, children }) {
  return (
    <Button
      className="profile-tab-button"
      variant="contained"
      type="button"
      color="secondary"
      onClick={onClick}
    >
      {children}
    </Button>
  );
}

ButtonInTabs.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.string.isRequired,
};

function ProfileMain() {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { loading, message } = useSelector((state) => state.dialog);

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    setErrorMessage(null);
    setOpenDialog(false);
  };

  const handleClickOpen = () => {
    setErrorMessage(null);
    setOpenDialog(true);
  };

  const handleClickConfirm = () => {
    dispatch(deleteProfile());
  };

  useEffect(() => {
    switch (loading) {
      case 'failed':
        setErrorMessage(message);
        setIsLoading(false);
        break;
      case 'pending':
        setIsLoading(true);
        break;
      case 'succeeded':
        setIsLoading(false);
        setOpenDialog(false);
        dispatch(logOut());
        break;
      default:
    }
    dispatch(reset());
  }, [loading, message]);

  return (
    <>
      <ProfileDialog
        open={openDialog}
        handleClose={handleClose}
        handleClickConfirm={handleClickConfirm}
        error={errorMessage}
        isLoading={isLoading}
      />
      <div className="col">
        <div className="row">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="tabs"
            centered
            TabIndicatorProps={{ sx: { display: 'none' } }}
            sx={{
              '& .MuiTabs-flexContainer': {
                flexWrap: 'wrap',
              },
            }}
          >
            <Tab label={t('pages.profile.userDetails')} {...a11yProps(0)} />
            <Tab label={t('pages.profile.myChildren')} {...a11yProps(1)} />
            <Tab label={t('pages.signUp.part2.addChild')} {...a11yProps(2)} />
            <Tab label={t('pages.profile.changePassword')} {...a11yProps(3)} />
            <ButtonInTabs
              onClick={() => { handleClickOpen(); }}
            >
              {t('pages.profile.button.deleteProfile')}
            </ButtonInTabs>
          </Tabs>
          <div className="col">
            <TabPanel value={value} index={0}>
              <UserForm />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <MyChildrenForm />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <AddChildForm />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <ChangePasswordForm />
            </TabPanel>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileMain;

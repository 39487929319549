import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function CalendarLegend({ slotStatuses }) {
  const { t } = useTranslation();
  return (
    <div className="col mb-4">
      <div className="row">
        {slotStatuses.map((statusNumber) => (
          <div key={statusNumber} className="col-auto col-sm-4 col-md-3">
            <div className="fc-legend-box">
              <span className={`fc-legend-dot slot-status-${statusNumber}`} />
              <span className="fc-legend-text">{t(`pages.events.slots.status${statusNumber}`)}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

CalendarLegend.propTypes = {
  slotStatuses: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default CalendarLegend;

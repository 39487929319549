import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import {
  FormHelperText, Alert, IconButton, Collapse,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import LoadingButton from '../../LoadingButton';
import UserPart from '../SignUp/UserPart';
import updateUserValidationSchema from '../../../helpers/user/updateUserValidationSchema';
import { updateAccount, reset } from '../services/authSlice';
import PromptIfDirty from '../../PromptIfDirty';

const contactDetail = [
  'zipCode', 'street', 'houseNumber', 'floor', 'door', 'phoneNumber',
];
const fields = [
  'userName', 'email', 'lastName', 'firstName',
];
const extrafields = [
  'newsletter',
];
const readOnlyFields = [...['lastName', 'firstName'], ...contactDetail.filter((field) => field !== 'phoneNumber')];

function UserForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const validationSchema = updateUserValidationSchema();
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { loading, message, account } = useSelector((state) => state.auth);

  const dataResolver = () => {
    const personalData = fields.reduce((formData, value) => (
      { ...formData, [value]: account[value] }
    ), {});

    const extraData = extrafields.reduce((formData, value) => (
      { ...formData, [value]: account[value] }
    ), {});

    if (account.contactDetail) {
      const contactData = contactDetail.reduce((formData, value) => (
        { ...formData, [value]: account.contactDetail[value] }
      ), {});

      return { ...personalData, ...contactData, ...extraData };
    }

    return { ...personalData, ...extraData };
  };

  const initialValues = dataResolver();

  useEffect(() => {
    if (!isSaving) {
      return;
    }
    switch (loading) {
      case 'failed':
        setShowSuccessAlert(false);
        setErrorMessage(message);
        setIsLoading(false);
        break;
      case 'pending':
        setShowSuccessAlert(false);
        setIsLoading(true);
        break;
      case 'succeeded':
        setShowSuccessAlert(true);
        setErrorMessage(null);
        setIsSaving(false);
        setIsLoading(false);
        break;
      default:
    }
    dispatch(reset());
  }, [loading, message]);

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const newData = { ...values };
          readOnlyFields.forEach((field) => {
            delete newData[field];
          });
          setIsSaving(true);
          dispatch(updateAccount(newData));
        }}
      >
        <div className="col">
          <Form noValidate>
            <PromptIfDirty init={initialValues} />
            <UserPart readOnly={readOnlyFields} newsletterOn />
            {errorMessage && (
              <div className="d-flex justify-content-center row">
                <div className="col-12 col-sm-auto col-md-auto mt-2">
                  <FormHelperText error>
                    {errorMessage}
                  </FormHelperText>
                </div>
              </div>
            )}
            <Collapse in={showSuccessAlert}>
              <div className="p-0 mt-1">
                <Alert
                  severity="success"
                  action={(
                    <IconButton
                      aria-label="close"
                      color="success"
                      size="small"
                      onClick={() => {
                        setShowSuccessAlert(false);
                      }}
                    >
                      <Close color="success" fontSize="inherit" />
                    </IconButton>
                  )}
                >
                  {t('common.saveSuccess')}
                </Alert>
              </div>
            </Collapse>
            <div className="d-flex justify-content-center row">
              <div className="col-12 col-sm-6 col-md-4 mt-4 mb-4">
                <LoadingButton
                  fullWidth
                  variant="contained"
                  title={t('common.button.save')}
                  type="submit"
                  loading={isLoading}
                />
              </div>
            </div>
          </Form>
        </div>
      </Formik>
    </div>
  );
}

export default UserForm;

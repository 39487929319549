import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import MultiStepForm, { FormStep } from '../MultiStepForm';
import createSignUpValidationSchema from '../../../helpers/user/createSignUpValidationSchema';
import createChildAndPolicyValidationSchema from '../../../helpers/user/createChildAndPolicyValidationSchema';
import UserPart from './UserPart';
import ChildPart from './ChildPart';
import PolicyPart from './PolicyPart';
import { register, reset } from '../services/authSlice';

function SignUpForm() {
  const { lang } = useSelector((state) => state.i18n);

  const initConnection = {
    lastName: '',
    firstName: '',
    phoneNumber: '',
    relationshipType: '',
  };

  const initChild = {
    lastName: '',
    firstName: '',
    birthDate: '',
    gender: '',
    howToCall: '',
    specialRequirements: '',
    languages: [lang],
    connections: [initConnection],
  };

  const initialValues = {
    userName: '',
    email: '',
    password: '',
    passwordAgain: '',
    lastName: '',
    firstName: '',
    zipCode: '',
    street: '',
    houseNumber: '',
    floor: '',
    door: '',
    phoneNumber: '',
    languages: [lang],
    children: [initChild],
    policyAccepted: false,
    privacyPolicyAccepted: false,
    newsletter: false,
  };

  const { t } = useTranslation();
  const navigate = useNavigate();
  const userSchema = createSignUpValidationSchema();
  const childSchema = createChildAndPolicyValidationSchema();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { loading, message, isAuthenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    switch (loading) {
      case 'failed':
        setErrorMessage(message);
        setIsLoading(false);
        break;
      case 'pending':
        setIsLoading(true);
        break;
      case 'succeeded':
        setIsLoading(false);
        navigate('/login');
        break;
      default:
    }
    dispatch(reset());
  }, [loading, message]);

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated]);

  return (
    <MultiStepForm
      initialValues={initialValues}
      onSubmit={(values) => {
        dispatch(register(values));
      }}
      errorMessage={errorMessage}
      isLoading={isLoading}
    >
      <FormStep
        stepName={t('pages.signUp.part1.title')}
        validationSchema={userSchema}
      >
        <UserPart />
      </FormStep>
      <FormStep
        stepName={t('pages.signUp.part2.title')}
        validationSchema={childSchema}
      >
        <ChildPart init={initChild} initConnection={initConnection} />
        <PolicyPart />
      </FormStep>
    </MultiStepForm>
  );
}

export default SignUpForm;

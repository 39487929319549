import React from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Alert, CircularProgress } from '@mui/material';
import useSWR from 'swr';
import {
  Card, CardImg, CardTitle, CardBody, CardText,
} from 'reactstrap';
import ApplyForm from './ApplyForm';

const noImage = `${process.env.PUBLIC_URL}/images/no_photo.png`;

function EventPage() {
  const { id } = useParams();
  const { t } = useTranslation();
  const { lang } = useSelector((state) => state.i18n);
  const apiUrl = `${process.env.REACT_APP_API_URL}/api/event/view/${id}`;
  const fetcher = (url) => axios.get(url).then((res) => res.data);
  const { data, error } = useSWR(apiUrl, fetcher);

  const getName = () => {
    let eventName;
    if (lang === 'hu') {
      eventName = data.name;
    } else {
      eventName = data.nameEnglish;
    }

    if (eventName === null || eventName.length === 0) {
      eventName = data.name;
    }

    return eventName;
  };

  const getDescription = () => {
    let eventDescription;
    if (lang === 'hu') {
      eventDescription = data.description;
    } else {
      eventDescription = data.descriptionEnglish;
    }

    if (eventDescription === null || eventDescription.length === 0) {
      eventDescription = data.description;
    }

    return eventDescription;
  };

  const getContent = () => {
    if (error) {
      return (
        <div className="py-5">
          <Alert severity="error" className="col">
            {t('common.error')}
          </Alert>
        </div>
      );
    }

    if (!data) {
      return (
        <div className="py-5 my-5">
          <CircularProgress />
        </div>
      );
    }

    return (data !== undefined)
      ? (
        <Card className="my-2 col-12">
          <div className="cover">
            <CardImg
              alt={getName()}
              src={data.image ? data.image : noImage}
            />
          </div>
          <CardBody>
            <CardTitle tag="div">
              <span>{getName()}</span>
            </CardTitle>
            <CardText tag="div" className="event-description">
              <div dangerouslySetInnerHTML={{ __html: getDescription() }} />
            </CardText>
            <ApplyForm event={data} lang={lang} />
          </CardBody>
        </Card>
      ) : (
        <div className="m-3 d-flex justify-content-center">
          {t('common.noContent')}
        </div>
      );
  };

  return (
    <div className="container event-page col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
      <div className="row d-flex justify-content-center">
        {getContent()}
      </div>
    </div>
  );
}

export default EventPage;

import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import { PersonOutline } from '@mui/icons-material';

function AccountButton({ isAuthenticated }) {
  const theme = useTheme();

  return (
    <div>
      <IconButton
        href={isAuthenticated ? '/profile' : '/login'}
        size="large"
        sx={{
          color: theme.palette.custom.main,
          marginLeft: 1,
          '&:hover': {
            color: theme.palette.custom.main,
            backgroundColor: 'inherit',
          },
        }}
      >
        <PersonOutline />
      </IconButton>
    </div>
  );
}

AccountButton.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};

export default AccountButton;

import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { useTheme } from '@mui/material/styles';
import {
  Dialog, DialogTitle, DialogActions, Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useCallbackPrompt from './useCallbackPrompt';

const compare = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2);

function PromptIfDirty({ init, reInit }) {
  const { t } = useTranslation();
  const { values, setValues } = useFormikContext();
  const equal = compare(init, values);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(!equal);
  const theme = useTheme();
  const PaperProps = {
    style: {
      borderRadius: '20px',
      color: theme.palette.custom.main,
      boxShadow: 'none',
    },
  };

  const reset = () => {
    if (reInit) {
      setValues(init);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={showPrompt}
      PaperProps={{ ...PaperProps }}
    >
      <DialogTitle
        textAlign="center"
        component="div"
        sx={{ padding: '20px 40px' }}
      >
        <div className="d-flex justify-content-center row">
          <div className="col col-md-auto">
            <span>{t('common.leavePrompt')}</span>
          </div>
        </div>
      </DialogTitle>
      <DialogActions
        className="mb-4"
        sx={{
          justifyContent: 'center',
          paddingLeft: '24px',
          paddingRight: '24px',
        }}
      >
        <div className="col">
          <div className="d-flex justify-content-center row">
            <div className="col-12 col-sm-6 col-md-4 mt-4">
              <Button
                fullWidth
                variant="contained"
                type="button"
                color="secondary"
                onClick={() => {
                  cancelNavigation();
                  reset();
                }}
              >
                {t('common.button.cancel')}
              </Button>
            </div>
            <div className="col-12 col-sm-6 col-md-4 mt-4 mb-2">
              <Button
                fullWidth
                variant="contained"
                type="button"
                onClick={confirmNavigation}
              >
                {t('common.button.yes')}
              </Button>
            </div>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
}

PromptIfDirty.propTypes = {
  init: PropTypes.shape({}).isRequired,
  reInit: PropTypes.bool,
};

PromptIfDirty.defaultProps = {
  reInit: false,
};

export default PromptIfDirty;

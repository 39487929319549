import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Dialog } from '@mui/material';
import PropTypes from 'prop-types';

function DynamicDialog({
  open, handleClose, type, children,
}) {
  const theme = useTheme();
  let PaperProps = {};
  if (type.indexOf('delete') === 0) {
    PaperProps = {
      style: {
        borderRadius: '20px',
        color: theme.palette.custom.main,
        boxShadow: 'none',
      },
    };
  }

  return (
    <Dialog
      fullWidth
      maxWidth={type.indexOf('delete') === 0 ? 'sm' : 'md'}
      open={open}
      onClose={handleClose}
      id="dynamic-dialog"
      PaperProps={{ ...PaperProps }}
    >
      {children}
    </Dialog>
  );
}

DynamicDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  type: PropTypes.string,
  children: PropTypes.element,
};

DynamicDialog.defaultProps = {
  type: 'update',
  children: undefined,
};

export default DynamicDialog;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useTheme } from '@mui/material/styles';
import { Button, Divider } from '@mui/material';
import InputField from '../InputField';
import getPattern from '../../../helpers/getPattern';

function ConnectionPart({
  childIndex, connectionArrayHelpers, init, readOnly, extraActions, actionCallbacks,
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { values } = useFormikContext();

  const renderElement = (childIdx, index, field) => {
    const fieldName = `children[${childIdx}].connections[${index}].${field}`;

    switch (field) {
      default:
        return (
          <InputField
            name={fieldName}
            label={t(`user.${field}`)}
            required
            hidden={field === 'id'}
            disabled={readOnly}
            pattern={getPattern(field)}
          />
        );
    }
  };

  return (
    <>
      {values.children[childIndex].connections.map((connectionItem, index) => {
        const fields = Object.keys(connectionItem);

        const sheet = fields.map(((field) => {
          let smNum;
          let mdNum;

          switch (field) {
            case 'firstName':
            case 'lastName':
            case 'phoneNumber':
            case 'relationshipType':
              smNum = 6;
              mdNum = 6;
              break;
            default:
              smNum = 12;
              mdNum = 12;
          }

          return (
          // eslint-disable-next-line react/no-array-index-key
            <div className={`col-12 col-sm-${smNum} col-md-${mdNum} mb-4`} key={`${field}-${index}`}>
              {renderElement(childIndex, index, field)}
            </div>
          );
        }));

        return (
        // eslint-disable-next-line react/no-array-index-key
          <Fragment key={`connectionSheet-${index}`}>
            {index > 0 && (
            <div className="col-12 mt-4 mb-2">
              <Divider
                className="connection-divider mb-2"
                variant="middle"
                sx={{
                  borderColor: theme.palette.primary.main,
                }}
              />
            </div>
            )}
            { sheet }
            { extraActions && (
            <div className="col-sm-12">
              <div className="d-flex justify-content-end row">
                <div className="col-12 col-sm-6 col-md-auto mb-2">
                  <Button
                    fullWidth
                    variant="contained"
                    type="button"
                    onClick={() => actionCallbacks.handle(connectionItem, 'updateConnection')}
                  >
                    {t('pages.signUp.part2.editConnection')}
                  </Button>
                </div>
                <div className="col-12 col-sm-6 col-md-auto mb-2">
                  <Button
                    fullWidth
                    variant="contained"
                    type="button"
                    onClick={() => actionCallbacks.handle(connectionItem, 'deleteConnection')}
                  >
                    {t('pages.signUp.part2.removeConnection')}
                  </Button>
                </div>
              </div>
            </div>
            )}
            {!readOnly && (
              <div className="d-flex justify-content-end">
                <div className="col-12 col-sm-auto col-md-auto mt-4">
                  <Button
                    variant="contained"
                    type="button"
                    onClick={() => {
                      connectionArrayHelpers.remove(index);
                      connectionArrayHelpers.form.setTouched({});
                    }}
                  >
                    {t('pages.signUp.part2.removeConnection')}
                  </Button>
                </div>
              </div>
            )}
          </Fragment>
        );
      })}
      {extraActions && (
        <div className="col-sm-12">
          <div className="d-flex justify-content-start row">
            <div className="col-12 col-sm-6 col-md-auto">
              <Button
                fullWidth
                variant="contained"
                type="button"
                onClick={() => {
                  const newInit = {
                    id: values.children[childIndex].id,
                    ...init,
                  };
                  actionCallbacks.handle(newInit, 'addConnection');
                }}
              >
                {t('pages.signUp.part2.addConnection')}
              </Button>
            </div>
          </div>
        </div>
      )}
      {!readOnly && (
        <div className="d-flex justify-content-start">
          <div className="col-12 col-sm-auto col-md-auto mt-4">
            <Button
              variant="contained"
              type="button"
              onClick={() => connectionArrayHelpers.push(init)}
            >
              {t('pages.signUp.part2.addConnection')}
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

ConnectionPart.propTypes = {
  childIndex: PropTypes.number.isRequired,
  connectionArrayHelpers: PropTypes.shape({
    form: PropTypes.shape({
      setTouched: PropTypes.func,
    }),
    push: PropTypes.func,
    remove: PropTypes.func,
  }).isRequired,
  init: PropTypes.shape({}).isRequired,
  readOnly: PropTypes.bool,
  extraActions: PropTypes.bool,
  actionCallbacks: PropTypes.objectOf(PropTypes.func),
};

ConnectionPart.defaultProps = {
  readOnly: false,
  extraActions: false,
  actionCallbacks: {},
};

export default ConnectionPart;

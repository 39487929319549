import React, { Fragment, useEffect, useState } from 'react';
import { useSearchParams, createSearchParams } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  format, startOfISOWeek, endOfISOWeek,
} from 'date-fns';
import localeHu from 'date-fns/locale/hu';
import localeEn from 'date-fns/locale/en-GB';
import { Alert, Button, CircularProgress } from '@mui/material';
import { CardGroup } from 'reactstrap';
import useSWR from 'swr';
import EventLink from './EventLink';
import { reset } from './services/eventSlice';
import Filter from './Filter';
import { isValidValue } from '../../helpers/isValidDate';

function EventList() {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentParams = Object.fromEntries([...searchParams]);
  const allowedParams = ['text', 'from', 'to'].reduce((params, key) => (
    { ...params, [key]: currentParams[key] }
  ), {});
  const { lang } = useSelector((state) => state.i18n);
  const locale = lang === 'hu' ? localeHu : localeEn;
  const basePath = `${process.env.REACT_APP_API_URL}/api/event/timetable`;
  const [today, setToday] = useState(
    isValidValue(allowedParams.from) ? new Date(allowedParams.from) : new Date(),
  );
  const [startIso, setStartIso] = useState(startOfISOWeek(today));
  const [endIso, setEndIso] = useState(endOfISOWeek(today));
  const startIsoF = format(new Date(startIso), 'PPP', { locale });
  const endIsoF = format(new Date(endIso), 'PPP', { locale });
  const initFrom = format(new Date(startIso), 'yyyy-MM-dd');
  const initTo = format(new Date(endIso), 'yyyy-MM-dd');

  const getUrl = () => {
    const { text, from, to } = allowedParams;

    if (!text) delete allowedParams.text;
    if ((!from || !to) || (!isValidValue(from) || !isValidValue(to)) || (from >= to)) {
      allowedParams.from = initFrom;
      allowedParams.to = initTo;
    }

    const newParams = createSearchParams(allowedParams);

    return `${basePath}?${newParams}`;
  };

  const [apiUrl, setApiUrl] = useState(new URL(getUrl()));
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fetcher = (url) => axios.get(url).then((res) => res.data);
  const { data, error } = useSWR(apiUrl, fetcher);

  useEffect(() => {
    dispatch(reset());
    setSearchParams(allowedParams);
  }, []);

  const getProductCards = (events, day) => events.map((event) => {
    const copy = { ...event, day };

    return (
      <div key={`${copy.id}`}>
        <EventLink event={copy} href={`/events/${copy.id}`} />
      </div>
    );
  });

  const next = () => {
    today.setDate(today.getDate() + 7);
    setToday(today);
    setStartIso(startOfISOWeek(today));
    setEndIso(endOfISOWeek(today));
    const start = format(new Date(startOfISOWeek(today)), 'yyyy-MM-dd');
    const end = format(new Date(endOfISOWeek(today)), 'yyyy-MM-dd');
    setSearchParams({ ...allowedParams, from: start, to: end });
    const newParams = createSearchParams({ ...allowedParams, from: start, to: end });

    setApiUrl(new URL(`${basePath}?${newParams}`));
  };

  const previous = () => {
    today.setDate(today.getDate() - 7);
    setToday(today);
    setStartIso(startOfISOWeek(today));
    setEndIso(endOfISOWeek(today));
    const start = format(new Date(startOfISOWeek(today)), 'yyyy-MM-dd');
    const end = format(new Date(endOfISOWeek(today)), 'yyyy-MM-dd');
    setSearchParams({ ...allowedParams, from: start, to: end });
    const newParams = createSearchParams({ ...allowedParams, from: start, to: end });
    setApiUrl(new URL(`${basePath}?${newParams}`));
  };

  const handleSearchButtonClick = (searchText) => {
    let params;
    if (searchText.length === 0) {
      delete allowedParams.text;
      params = allowedParams;
    } else {
      params = { ...allowedParams, text: searchText };
    }
    setSearchParams(params);
    const newParams = createSearchParams(params);
    setApiUrl(new URL(`${basePath}?${newParams}`));
  };

  const getSearchPanel = () => (
    <div className="col-12">
      <Filter handleSearchButtonClick={handleSearchButtonClick} />
    </div>
  );

  const getControlPanel = () => (
    <div className="col">
      <div className="row week_control_panel">
        <div className="col-12 col-sm-12 col-md-auto">
          <Button
            variant="contained"
            type="button"
            onClick={previous}
          >
            {t('pages.events.button.previousWeek')}
          </Button>
        </div>
        <div className="col-12 col-sm-12 col-md-auto">
          <div className="week-date">
            <span>{`${startIsoF}-${endIsoF}`}</span>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-auto">
          <Button
            variant="contained"
            type="button"
            onClick={next}
          >
            {t('pages.events.button.nextWeek')}
          </Button>
        </div>
      </div>
    </div>
  );

  const getWeek = () => {
    if (Array.isArray(data) && data.length === 0) {
      return (
        <div className="m-3 d-flex justify-content-center">
          {t('common.noContent')}
        </div>
      );
    }

    const days = Object.keys(data).map((day) => {
      const dayName = format(new Date(day), 'EEEE', { locale });
      const dayF = format(new Date(day), 'PPP', { locale });
      return (data[day]?.length > 0) ? (
        <Fragment key={day}>
          <div className="col-12">
            <div className="day-header">
              <div className="day">
                {dayName}
                <span>{dayF}</span>
              </div>
            </div>
          </div>
          <CardGroup className="gap-4 justify-content-center">
            { getProductCards(data[day], day) }
          </CardGroup>
        </Fragment>
      ) : null;
    });

    return days;
  };

  const getContent = () => {
    if (error) {
      return (
        <Alert severity="error" className="col">
          {t('common.error')}
        </Alert>
      );
    }

    if (!data) {
      return (
        <div className="d-flex justify-content-center my-5">
          <CircularProgress />
        </div>
      );
    }

    return (data)
      ? (
        <>
          { getSearchPanel() }
          { getControlPanel() }
          { getWeek() }
        </>
      ) : (
        <div className="m-3 d-flex justify-content-center">
          {t('common.noContent')}
        </div>
      );
  };

  return (
    <div className="event-container">
      {getContent()}
    </div>
  );
}

export default EventList;

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { Select, MenuItem } from '@mui/material';
import { setLang } from './services/i18nSlice';

function LanguageSwitcher() {
  const theme = useTheme();
  const lang = useSelector((state) => state.i18n.lang);
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  return (
    <Select
      className="lang-switcher"
      IconComponent={() => null}
      sx={{ color: theme.palette.custom.main, borderColor: 'white' }}
      variant="outlined"
      value={lang}
      onChange={(e) => dispatch(setLang(e.target.value))}
    >
      <MenuItem value="hu">HU</MenuItem>
      <MenuItem value="en">EN</MenuItem>
    </Select>
  );
}

export default LanguageSwitcher;

import * as yup from 'yup';
import i18n from 'i18next';
import addValidation from './addValidation';

const { t } = i18n;

const createChildAndPolicyValidationSchema = () => (yup.object({
  children: yup.array()
    .of(
      yup.object({
        lastName: addValidation('lastName'),
        firstName: addValidation('firstName'),
        birthDate: addValidation('birthDate'),
        gender: addValidation('gender'),
        howToCall: addValidation('howToCall'),
        specialRequirements: addValidation('specialRequirements'),
        connections: yup.array()
          .of(
            yup.object({
              lastName: addValidation('lastName'),
              firstName: addValidation('firstName'),
              phoneNumber: addValidation('phoneNumber'),
            }),
          ),
      }),
    ),
  policyAccepted: yup
    .bool()
    .test('is-true', t('error.acceptPolicy'), (value) => value === true),
  privacyPolicyAccepted: yup
    .bool()
    .test('is-true', t('error.acceptPrivacyPolicy'), (value) => value === true),
}));

export default createChildAndPolicyValidationSchema;

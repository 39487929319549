import React from 'react';
import ForgotPasswordForm from '../../components/User/ForgotPassword/ForgotPasswordForm';

export default function ForgotPassword() {
  return (
    <div className="container container__wrap forgot col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
      <div className="row d-flex justify-content-center">
        <ForgotPasswordForm />
      </div>
    </div>
  );
}

import {
  differenceInYears, differenceInWeeks, differenceInMonths, parse,
} from 'date-fns';
import i18n from 'i18next';
import * as yup from 'yup';

export function calculateAge(birthDay, metric, event) {
  let day = new Date();
  if (event.whenCheckChildAge.value === '2') {
    day = new Date(event.firstApplicationSlot);
  }
  const birthDate = parse(birthDay, 'yyyy-MM-dd', new Date());

  switch (metric) {
    case '2':
      return differenceInWeeks(day, birthDate);
    case '1':
      return differenceInMonths(day, birthDate);
    default:
      return differenceInYears(day, birthDate);
  }
}

export function getChildrenOptions(accountData, eventData, langData) {
  if (eventData.childrenSelectorOff) {
    return [];
  }

  const results = accountData.children.reduce((acc, obj) => {
    const inGroup = eventData.groups.some(
      // eslint-disable-next-line max-len
      (group) => group.minimumAgeLimit <= calculateAge(obj.birthDate, group.minimumAgeLimitMetric.value, eventData)
      // eslint-disable-next-line max-len
      && calculateAge(obj.birthDate, group.maximumAgeLimitMetric.value, eventData) < group.maximumAgeLimit,
    );
    if (inGroup) {
      let temp = {};
      temp = {
        value: obj.id,
        label: langData === 'hu' ? obj.nameHU : obj.name,
      };
      return acc.concat(temp);
    }
    return acc;
  }, []);

  if (results.length === 0) {
    results.push({ value: '', label: i18n.t('pages.events.apply.noChild') });
  }

  return results;
}

export function getColorClass(status, IsTemporary, isRemoval) {
  if (IsTemporary) {
    return [`slot-status-${status} temp${isRemoval ? ' removed' : ''}`];
  }

  return [`slot-status-${status}`];
}

function renderChildrenSelector(event) {
  if (event.withoutChildrenApplication) {
    return false;
  }
  if (!event.withoutChildrenApplication && event.childrenSelectorOff) {
    return false;
  }
  return true;
}

export function getInitialValues(
  accountData,
  isAdminLogin,
  eventData,
  foundData = undefined,
  searchParams = null,
) {
  let searchParamChildId = null;
  if (searchParams) {
    searchParamChildId = searchParams.get('childId')?.length > 0
      ? parseInt(searchParams.get('childId'), 10) : null;
  }
  const isMyChild = accountData.children.find((child) => child.id === searchParamChildId);
  if (!isMyChild) {
    searchParamChildId = null;
  }

  const init = {
    eventId: eventData.id,
    isAdminApply: isAdminLogin,
  };

  // if (eventData.withoutChildrenApplication) {
  //   init.userId = accountData.id;
  // }
  if (renderChildrenSelector(eventData)) {
    init.childId = '';
  }
  if (renderChildrenSelector(eventData) && foundData) {
    init.childId = searchParamChildId === null ? foundData.child.id : searchParamChildId;
  }
  if (renderChildrenSelector(eventData) && !foundData && searchParamChildId !== null) {
    init.childId = searchParamChildId;
  }
  if (eventData.needHungarianLanguage) {
    init.speakHungarian = false;
  }
  if (eventData.needHungarianLanguage && foundData) {
    init.speakHungarian = true;
  }

  return init;
}

export function getValidationSchema(eventData) {
  if (renderChildrenSelector(eventData) && eventData.needHungarianLanguage) {
    return (yup.object({
      childId: yup
        .string()
        .required(i18n.t('error.requiredField')),
      speakHungarian: yup
        .bool()
        .test('is-true', i18n.t('error.requiredField'), (value) => value === true),
    }));
  }
  if (renderChildrenSelector(eventData)) {
    return (yup.object({
      childId: yup
        .string()
        .required(i18n.t('error.requiredField')),
    }));
  }
  if (eventData.needHungarianLanguage) {
    return (yup.object({
      speakHungarian: yup
        .bool()
        .test('is-true', i18n.t('error.requiredField'), (value) => value === true),
    }));
  }
  return (yup.object({}));
}
